export const calculateYearlySavings = (
  subscriptionPlans: Array<{ interval: string; price: string }>
) => {
  // Find the yearly and monthly plans
  const yearly = subscriptionPlans.find((plan) => plan.interval === 'year');
  const monthly = subscriptionPlans.find((plan) => plan.interval === 'month');

  // If either plan is missing, return null
  if (!yearly || !monthly) return null;

  // Convert prices to numbers
  const yearlyPrice = parseFloat(yearly.price);
  const monthlyPrice = parseFloat(monthly.price);

  // Calculate savings: yearlyPrice - (12 * monthlyPrice)
  const savings = 12 * monthlyPrice - yearlyPrice;

  // Return rounded savings
  return Math.round(savings);
};
