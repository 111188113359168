import React, { useState, useEffect } from 'react';
import {
  CampaignsFilter,
  CampaignsListHeader,
  CampaignsPagination,
  CampaignsSearchWithDropdown,
  CampaignsTable,
} from './components';
import {
  CampaignDataProps,
  CampaignsPageStateProps,
} from '../startCampaign/components/startCampaignModels';
import { getCampaigns } from '../../core/requests/requests';

const Campaigns: React.FC = () => {
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [campaignsData, setCampaignsData] = useState<CampaignDataProps[]>([]);
  const [campaignsPageState, setCampaignsPageState] =
    useState<CampaignsPageStateProps>({
      showCampaignsDropdown: 'All leads',
      sortCampaignsDropdown: 'Default',
      filterCampaignsTags: [],
      searchQuery: '',
      actionDropdown: 'Action',
      currentPage: 1,
      itemsPerPage: 10,
    });

  const fetchCampaigns = async () => {
    try {
      const { data } = await getCampaigns();

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(a.updatedAt || 0).getTime();
        const dateB = new Date(b.updatedAt || 0).getTime();
        return dateB - dateA;
      });

      setCampaignsData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const filteredCampaigns = campaignsData?.filter(
    (campaign) =>
      campaign?.name &&
      campaign.name
        .toLowerCase()
        .includes((campaignsPageState?.searchQuery || '').toLowerCase())
  );

  let sortedCampaigns: CampaignDataProps[] = [];

  if (filteredCampaigns) {
    sortedCampaigns = [...filteredCampaigns];

    if (campaignsPageState?.sortCampaignsDropdown === 'Date (newest)') {
      sortedCampaigns.sort((a, b) => {
        const createdAtA = new Date(a?.createdAt ?? '');
        const createdAtB = new Date(b?.createdAt ?? '');
        return createdAtB.getTime() - createdAtA.getTime();
      });
    }
  } else if (campaignsPageState?.sortCampaignsDropdown === 'Date (oldest)') {
    sortedCampaigns.sort((a, b) => {
      const startDateA = new Date(a?.startDate ?? '');
      const startDateB = new Date(b?.startDate ?? '');
      return startDateA.getTime() - startDateB.getTime();
    });
  } else if (campaignsPageState?.sortCampaignsDropdown === 'Name (A-Z)') {
    sortedCampaigns.sort((a, b) =>
      (a?.name || '').localeCompare(b?.name || '')
    );
  } else if (campaignsPageState?.sortCampaignsDropdown === 'Name (Z-A)') {
    sortedCampaigns.sort((a, b) =>
      (b?.name || '').localeCompare(a?.name || '')
    );
  }

  const totalPaginationPages = Math.ceil(
    (sortedCampaigns?.length || 1) / campaignsPageState?.itemsPerPage
  );

  const indexOfLastLead =
    campaignsPageState?.currentPage * campaignsPageState?.itemsPerPage;
  const indexOfFirstLead =
    indexOfLastLead - campaignsPageState?.itemsPerPage + 1;

  const currentCampaigns = sortedCampaigns?.slice(
    indexOfFirstLead - 1,
    indexOfLastLead
  );

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleCloseFilter = () => {
    setIsFilterVisible(false);
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem ">
      <div className="d-flex-start-start-row w-100">
        <div className="d-flex-start-start w-100">
          <CampaignsListHeader
            toggleFilter={toggleFilter}
            campaignsPageState={campaignsPageState}
            setCampaignsPageState={setCampaignsPageState}
          />
          {currentCampaigns?.length > 0 ? (
            <div className="d-flex-start-start w-100 bg-white b-radius-1_125rem p-0_625rem">
              <CampaignsSearchWithDropdown
                campaignsPageState={campaignsPageState}
                setCampaignsPageState={setCampaignsPageState}
              />
              <CampaignsTable
                currentCampaigns={currentCampaigns}
                fetchCampaigns={fetchCampaigns}
              />
              <CampaignsPagination
                campaignsPageState={campaignsPageState}
                setCampaignsPageState={setCampaignsPageState}
                totalPaginationPages={totalPaginationPages}
                totalLeadsItems={sortedCampaigns?.length || 0}
              />
            </div>
          ) : (
            <div className="d-flex-center w-100 p-2rem bg-white b-radius-1_125rem">
              <span className="ff-inter fs-1_25rem fw-600 c-9ca3af">
                No campaigns found.
              </span>
            </div>
          )}
        </div>
        {isFilterVisible && (
          <CampaignsFilter
            setCampaignsPageState={setCampaignsPageState}
            campaignsPageState={campaignsPageState}
            handleCloseFilter={handleCloseFilter}
          />
        )}
      </div>
    </div>
  );
};

export default Campaigns;
