import React, { useState, useEffect } from 'react';
import { InfoIcon } from '../../../assets';
import { AddPaymentMethodButton, PaymentMethodCard } from './';
import { PaymentMethodProps } from '../../../core/types/_models';
import {
  getPaymentMethods,
  setDefaultPaymentMethod,
} from '../../../core/requests/requests';
import { PaymentsMethodsProps } from './paymentsModels';

const PaymentsMethods: React.FC<PaymentsMethodsProps> = ({
  handleSelectSubscriptionPlan,
  isModal,
}) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodProps[]>(
    []
  );
  const [activePaymentMethodId, setActivePaymentMethodId] =
    useState<string>('');

  const fetchPaymentMethods = async () => {
    try {
      const { data } = await getPaymentMethods();
      if (data) {
        setPaymentMethods(data);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  const handleSaveDefaultPaymentMethod = async () => {
    try {
      const response = await setDefaultPaymentMethod(activePaymentMethodId);
      if (response?.status === 200) {
        fetchPaymentMethods();
        if (handleSelectSubscriptionPlan) {
          handleSelectSubscriptionPlan();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const setDefaultPaymentMethodId = () => {
      if (paymentMethods.length > 0) {
        const defaultPaymentMethod = paymentMethods.find(
          (method) => method.isDefault
        );

        if (defaultPaymentMethod) {
          setActivePaymentMethodId(defaultPaymentMethod.id);
        } else {
          console.log('No default payment method found');
        }
      } else {
        setActivePaymentMethodId('');
      }
    };
    setDefaultPaymentMethodId();
  }, [paymentMethods]);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <div className="d-flex-start-start w-100 bg-white shadow-box-9 b-radius-0_5rem p-1_5rem">
      {!isModal && (
        <div className="d-flex-row mb-1_5rem">
          <span className="ff-inter c-111928 fw-700 fs-1_25rem">
            Payment methods
          </span>
          <div className="d-flex-center ml-0_5rem icon-hovered">
            <InfoIcon />
          </div>
        </div>
      )}
      {paymentMethods?.length > 0 ? (
        paymentMethods.map((paymentMethod) => (
          <PaymentMethodCard
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            activePaymentMethodId={activePaymentMethodId}
            setActivePaymentMethodId={setActivePaymentMethodId}
            fetchPaymentMethods={fetchPaymentMethods}
          />
        ))
      ) : (
        <div className="d-flex-center w-100 p-1rem mb-1_5rem">
          <span className="ff-inter c-6b7280 fw-600 fs-1_125rem">
            There are no payment methods yet
          </span>
        </div>
      )}

      <AddPaymentMethodButton fetchPaymentMethods={fetchPaymentMethods} />

      {paymentMethods?.length > 0 && (
        <button
          className={`${isModal ? 'w-12_5rem' : 'w-8_625rem'} btn-subscriptions-primary`}
          onClick={handleSaveDefaultPaymentMethod}
        >
          {isModal ? 'Select payment method' : 'Save changes'}
        </button>
      )}
    </div>
  );
};

export default PaymentsMethods;
