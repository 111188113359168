import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import { HelpImagePNG } from '../../assets';
import { FeedbackPayloadProps } from '../../core/types/_models';
import { sendFeedback } from '../../core/requests/requests';
import { ConfirmModal, InfoModal } from '../../components';

const Help: React.FC = () => {
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSendFeedback = async (feedbackPayload: FeedbackPayloadProps) => {
    try {
      const { data } = await sendFeedback(feedbackPayload);
      if (data) {
        setIsFeedbackSent(true);
      }
    } catch (error: any) {
      setErrorMessage(error.response?.data || 'An unexpected error occurred.');
    }
  };

  const handleCloseInfoModal = () => {
    setErrorMessage('');
  };

  return (
    <div className="d-flex-start-center w-100 p-relative">
      <div className="d-flex-start-center p-absolute top-0 left-0 z0 h-25rem bg-white w-100"></div>
      {isFeedbackSent ? (
        <div className="d-flex-start-center w-44rem z1 pt-4_25rem">
          <div className="d-flex-center w-8rem h-8rem">
            <img src={HelpImagePNG} alt="help" className="w-100" />
          </div>
          <div className="d-flex-center w-100 pt-0_5rem">
            <span className="ff-poppins fw-600 fs-2_625rem ls-0_01rem c-171725">
              Thank you for your feedback!
            </span>
          </div>
          <p className="ff-poppins fw-500 fs-1_25rem ls-0_01rem c-171725 pt-4_25rem">
            Your message has been successfully sent.
          </p>
        </div>
      ) : (
        <div className="d-flex-start-center w-44rem z1 pt-4_25rem">
          <div className="d-flex-center w-8rem h-8rem">
            <img src={HelpImagePNG} alt="help" className="w-100" />
          </div>
          <div className="d-flex-center w-100 pt-0_5rem">
            <span className="ff-poppins fw-600 fs-2_625rem ls-0_01rem c-171725">
              How Can We Help?
            </span>
          </div>

          {/* Formik form */}
          <div className="d-flex-start-start w-100 p-1_5rem b-1 bg-white mt-1_5rem b-radius-1_25rem">
            <Formik
              initialValues={{
                title: '',
                message: '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                // Handle form submission
                handleSendFeedback(values as FeedbackPayloadProps);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="w-100">
                  <div className="d-flex-start-start w-100 mb-1rem">
                    <label className="ff-inter fw-500 pb-0_375rem fs-0_875rem">
                      Subject
                    </label>
                    <Field
                      type="text"
                      name="title"
                      placeholder="Title"
                      className="payment-details-input w-100"
                    />
                  </div>
                  <div className="d-flex-start-start w-100 mb-1_5rem">
                    <label className="ff-inter fw-500 pb-0_375rem fs-0_875rem">
                      Description
                    </label>
                    <Field
                      as="textarea"
                      name="message"
                      placeholder="Message"
                      className="payment-details-textarea w-100"
                    />
                  </div>

                  <div className="d-flex-center">
                    <button
                      type="submit"
                      className="btn w-100 bg-1e75ff  p-0_75rem-1_5rem b-radius-0_5rem"
                      disabled={isSubmitting}
                    >
                      <span className="c-fafafb fs-0_875rem fw-600">
                        Send Message
                      </span>
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <InfoModal title={errorMessage} onClose={handleCloseInfoModal} />
        </div>
      )}
    </div>
  );
};

export default Help;
