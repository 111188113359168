import React, { useState, useRef } from 'react';
import { SelectCampaignToneProps } from './campaignSettingsFormModels';
import { ChevronDownIcon, InfoIcon } from '../../../../assets';
import { CampaignToneDropdownConfig } from '../../../../config/CampaignsConfig';
import useOutsideClick from '../../../../hooks/useOutsideClicks';

const SelectCampaignTone: React.FC<SelectCampaignToneProps> = ({
  formikValues,
  setFormikValues,
}) => {
  const [isToneDropdownOpen, setIsToneDropdownOpen] = useState(false);

  const toneDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    toneDropdownRef,
    () => setIsToneDropdownOpen(false),
    excludeOutsideClickRef
  );

  const toggleToneDropdown = () => {
    setIsToneDropdownOpen((prevState) => !prevState);
  };

  const handleChangeTone = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      toneSelection: value,
    }));
    setIsToneDropdownOpen(false);
  };

  return (
    <div className="d-flex-start-start w-100 p-relative mb-1rem">
      <div className="d-flex-row">
        <label className="campaign-label-input">Tone Selection</label>
        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
          <InfoIcon />
        </div>
      </div>
      <div
        className="d-flex-row campaign-input w-100 pointer"
        onClick={toggleToneDropdown}
        ref={excludeOutsideClickRef}
      >
        <span>{formikValues?.toneSelection}</span>
        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
          <ChevronDownIcon
            className={`${isToneDropdownOpen && 't-rotate-180'}`}
          />
        </div>
      </div>
      {isToneDropdownOpen && (
        <div
          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
          ref={toneDropdownRef}
        >
          {CampaignToneDropdownConfig.map((option, index) => (
            <div
              key={index}
              className={`${option === formikValues?.toneSelection && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
              onClick={() => handleChangeTone(option)}
            >
              <span className="pl-0_5rem f-roboto-m-primary fs-0_875rem">
                {option}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCampaignTone;
