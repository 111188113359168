import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import {
  LoginPNG,
  CheckboxActiveIcon,
  HidePasswordIcon,
  ShowPasswordIcon,
} from '../../assets';
import { getAuthToken, getUser } from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';
import {
  firstNameValidationSchema,
  passwordValidationSchema,
} from './components/ValidationSchemaItems';

const Login: React.FC = () => {
  const { setAuthLogin, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [focusedFields, setFocusedFields] = useState({
    firstName: false,
    password: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validationSchema = Yup.object().shape({
    firstName: firstNameValidationSchema,
    password: passwordValidationSchema,
  });

  const handleSubmit = async (values: any) => {
    try {
      const { data } = await getAuthToken(values.firstName, values.password);
      if (data) {
        setAuthLogin(data);
        const response = await getUser(data.userId, data.accessToken);
        setCurrentUser(response.data);
        navigate('/');
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || 'An unexpected error occurred.';
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="d-flex-center-start-row size-max">
      <div className="d-flex-center h-100 w-50 bg-fffefe z2 p-relative">
        <div className="d-flex-start-start w-80 max-w-40rem">
          <div className="d-flex-center w-100">
            <h1 className="auth-title">Log In</h1>
          </div>
          <div className="d-flex-start w-100 bg-white z3 auth-form">
            <Formik
              initialValues={{
                firstName: '',
                password: '',
                rememberMe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, isValid, values, setFieldValue }) => (
                <Form className="w-100">
                  <div className="d-flex-row form-row mb-1_5rem w-100">
                    <div className="form-item w-100">
                      <Field
                        type="text"
                        name="firstName"
                        className={`form-control ${errors.firstName ? 'error-border' : ''} `}
                        placeholder="Username / Email Address"
                        onFocus={() => handleFieldFocus('firstName')}
                        onBlur={() => handleFieldBlur('firstName')}
                      />
                      {errors.firstName && (
                        <div className="form-error">{errors.firstName}</div>
                      )}
                      {focusedFields.firstName && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.firstName ? 'error-title' : 'correct-title'
                            }`}
                          >
                            Your Email / Username
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex-row form-row mb-1_25rem">
                    <div className="form-item w-100 ">
                      <Field
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className={`form-control ${errors.password ? 'error-border' : ''} `}
                        placeholder="Password"
                        onFocus={() => handleFieldFocus('password')}
                        onBlur={() => handleFieldBlur('password')}
                      />
                      {errors.password && (
                        <div className="form-error">{errors.password}</div>
                      )}
                      {focusedFields.password && (
                        <div className="d-flex-center title-container">
                          <span
                            className={`form-title ${
                              errors.password ? 'error-title' : 'correct-title'
                            }`}
                          >
                            Password
                          </span>
                        </div>
                      )}
                      <div
                        className="show-password-container"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <HidePasswordIcon />
                        ) : (
                          <ShowPasswordIcon />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex-row-space-between mb-1_875rem">
                    <div className="checkbox-form ">
                      <label>
                        <Field
                          type="checkbox"
                          name="rememberMe"
                          className="checkbox-input"
                        />{' '}
                        Remember Me
                      </label>
                      {values?.rememberMe && (
                        <div
                          className="checkbox-active"
                          onClick={() => setFieldValue('rememberMe', false)}
                        >
                          <CheckboxActiveIcon />
                        </div>
                      )}
                    </div>
                    <Link to="/auth/forgot-password" className="secondary-link">
                      Forgot Password
                    </Link>
                  </div>
                  <div className="d-flex-row form-row p-relative">
                    <button type="submit" className="btn btn-primary w-100">
                      {isSubmitting ? 'Submitting...' : 'Login'}
                    </button>
                    {errorMessage?.length > 0 && (
                      <div className="d-flex-row w-100 p-absolute top-_-1rem ">
                        <span className="error-message ">{errorMessage}</span>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="d-flex-center w-100 mt-1_75rem">
            <Link className="primary-link" to="/auth/registration">
              Don't have an Astreaux account? Sign up
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex-center h-100 w-50 z2 p-relative">
        <div className="d-flex-start-start w-80">
          <img src={LoginPNG} alt="login" className="w-100 z-2" />
        </div>
      </div>
    </div>
  );
};

export default Login;
