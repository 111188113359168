import React, { useRef, useState } from 'react';

import { useAppData } from '../../../context/AppContext';
import { ExportIcon } from '../../../assets';
import {
  importFilesByCampaignId,
  validateFile,
} from '../../../core/requests/requests';
import { FileProcessorProps } from './startCampaignModels';
import { InfoModal } from '../../../components';
import { MAX_FILE_SIZE } from '../../../config/appConfig';

const FileProcessor: React.FC<FileProcessorProps> = ({ setFileToUpload }) => {
  const { currentCampaign, setShowModalBackground } = useAppData();
  const currentCampaignId = currentCampaign?.id;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setShowModalBackground(false);
    setErrorMessage(null);
  };

  const handleOpenErrorModal = () => {
    setShowErrorModal(true);
    setShowModalBackground(true);
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open the file dialog
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]; // Get the selected file
    if (file) {
      const allowedTypes = [
        'application/pdf',
        'application/msword', // Word (.doc)
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word (.docx)
        'text/plain', // Text files
        'application/vnd.ms-excel', // Excel (.xls)
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel (.xlsx)
        'text/csv', // CSV files
      ];

      if (!allowedTypes.includes(file.type)) {
        setErrorMessage('Unsupported file type. Please select a valid file.');
        handleOpenErrorModal();
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage('File size exceeds the allowed limit of 5MB.');
        handleOpenErrorModal();
        return;
      }

      try {
        const { data } = await validateFile(file);
        if (data) {
          setFileName(`${file?.name}`);
          if (currentCampaignId) {
            try {
              const { data } = await importFilesByCampaignId(
                file,
                currentCampaignId
              );
              if (data) {
                setErrorMessage('File uploaded successfully');
                handleOpenErrorModal();
              }
            } catch (error) {
              setErrorMessage(`Error uploading file:', ${error}`);
              handleOpenErrorModal();
            }
          } else {
            setFileToUpload(file);
          }
        }
      } catch (error: any) {
        setErrorMessage(error.response.data.message || 'Unexpected error');
        handleOpenErrorModal();
        setFileToUpload(null);
        setFileName(null);
        return;
      }
    } else {
      setFileName(null);
      setFileToUpload(null);
    }
  };

  return (
    <>
      <div className="d-flex-start-start mt-1_5rem w-100">
        <span className="fw-600 fs1_125rem">Knowledge Hub</span>
        <div className="d-flex-start-start mt-0_5rem w-100">
          <div
            className="d-flex-row w-100  hovered"
            onClick={handleImportClick}
          >
            <div className="d-flex-center mr-0_5rem">
              <ExportIcon />
            </div>
            <span className="ff-inter fw-500 c-1751d0">Import Files</span>
            <span className="ml-1rem ff-inter">{fileName}</span>
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      </div>
      {showErrorModal ? (
        <InfoModal
          title={errorMessage || 'Unknown Error'}
          onClose={handleCloseErrorModal}
        />
      ) : null}
    </>
  );
};

export default FileProcessor;
