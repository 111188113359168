import React, { useRef, useState } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClicks';
import { ConversationListSortDropdownProps } from './conversationModels';
import { TriangleIcon } from '../../../assets';
import { ConversationListSortOptions } from '../../../config/CampaignsConfig';

const ConversationListSortDropdown: React.FC<
  ConversationListSortDropdownProps
> = ({ setChats, chats }) => {
  const [sortDropdownValue, setSortDropdownValue] = useState('updatedAtDesc'); // Default value
  const [sortDropdown, setSortDropdown] = useState(false);
  const showSortDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideSortClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    showSortDropdownRef,
    () => setSortDropdown(false),
    excludeOutsideSortClickRef
  );

  const handleSortChange = (sortValue: string) => {
    setSortDropdownValue(sortValue);

    const sortedChats = [...chats].sort((a, b) => {
      if (sortValue === 'updatedAtDesc') {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      } else if (sortValue === 'updatedAtAsc') {
        return (
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        );
      } else if (sortValue === 'createdAtDesc') {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      } else if (sortValue === 'createdAtAsc') {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }
      return 0; // Default case
    });

    setChats(sortedChats);
    setSortDropdown(false);
  };

  return (
    <div className="d-flex-row">
      <div className="d-flex-row-space-between  h-2_375rem bg-white b-radius-0_5rem p-relative pointer">
        <div
          className="d-flex-row pl-0_75rem w-100"
          onClick={() => setSortDropdown(!sortDropdown)}
          ref={excludeOutsideSortClickRef}
        >
          <div className="d-flex-row-space-between w-100">
            <span className="sort-title">Sort by:</span>
            <div className="dropdown-wrapper">
              <span className="sort-dropdown-item pl-0_375rem">
                {
                  ConversationListSortOptions.find(
                    (option) => option.value === sortDropdownValue
                  )?.label
                }
              </span>
            </div>
            <div className="d-flex-center w-1_75rem h-100 bl-1-l-gray">
              <TriangleIcon
                className={`${sortDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
              />
            </div>
          </div>
        </div>

        {sortDropdown && (
          <div
            className="p-absolute top-2_375rem z1 bg-white w-100 left-0 shadow-box-12"
            ref={showSortDropdownRef}
          >
            {ConversationListSortOptions.map((option) => (
              <div
                className="d-flex-center-start h-2_375rem bt-1 hovered"
                key={option.value}
                onClick={() => handleSortChange(option.value)}
              >
                <span className="sort-dropdown-item pl-0_75rem">
                  {option.label}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationListSortDropdown;
