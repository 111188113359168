import React, { useState, useRef } from 'react';
import { SelectAdCampaignProps } from './campaignSettingsFormModels';
import { ChevronDownIcon, InfoIcon } from '../../../../assets';
import { CampaignPlatformDropdownConfig } from '../../../../config/CampaignsConfig';
import useOutsideClick from '../../../../hooks/useOutsideClicks';

const SelectCampaignAdPlatform: React.FC<SelectAdCampaignProps> = ({
  formikValues,
  setFormikValues,
  errors,
  setFieldValue,
}) => {
  const [isPlatformDropdownOpen, setIsPlatformDropdownOpen] = useState(false);

  const adPlatformDropdown = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    adPlatformDropdown,
    () => setIsPlatformDropdownOpen(false),
    excludeOutsideClickRef
  );

  const togglePlatformDropdown = () => {
    setIsPlatformDropdownOpen((prevState) => !prevState);
  };

  const handleChangePlatform = (value: string) => {
    setFormikValues((prevState) => ({
      ...prevState,
      platforms: [value], // replace the entire platforms array with the new value
    }));
    setIsPlatformDropdownOpen(false);
  };

  return (
    <div className="d-flex-start-start w-100 p-relative mb-1rem">
      <div className="d-flex-row">
        <label className="campaign-label-input">
          Communication Channel Selection
        </label>
        <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
          <InfoIcon />
        </div>
      </div>
      <div
        className={`${errors?.platforms && 'error'} d-flex-row campaign-input w-100 pointer`}
        onClick={togglePlatformDropdown}
        ref={excludeOutsideClickRef}
      >
        <span>{formikValues?.platforms[0]}</span>
        <div className="d-flex-center p-absolute right-0_625rem top-2_8rem">
          <ChevronDownIcon
            className={`${isPlatformDropdownOpen && 't-rotate-180'}`}
          />
        </div>
      </div>

      {errors?.platforms && (
        <div className="form-error">{errors?.platforms}</div>
      )}
      {isPlatformDropdownOpen && (
        <div
          className="p-absolute z1 w-100 overflow-auto max-h-15rem top-4_5rem bg-white shadow-box-12"
          ref={adPlatformDropdown}
        >
          {CampaignPlatformDropdownConfig.map((option, index) => (
            <div
              key={index}
              className={`${option === formikValues?.platforms[0] && 'bg-badge'} d-flex-center-start h-2_375rem bb-1 hovered`}
              onClick={() => {
                if (
                  option === 'SMS' ||
                  option === 'Facebook' ||
                  option === 'Instagram'
                ) {
                  handleChangePlatform(option); // Call your custom function
                  setFieldValue('platforms', [option]); // Update Formik values
                }
              }} // Only trigger if option is "SMS"
            >
              <span
                className={`${option === 'SMS' || option === 'Facebook' || option === 'Instagram' ? 'f-roboto-m-primary' : 'c-secondary'}  pl-0_5rem f-roboto-m-primary fs-0_875rem`}
              >
                {option}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default SelectCampaignAdPlatform;
