import React, { useState } from 'react';

import { CrossIcon } from '../../../assets';
import {
  CampaignDataProps,
  ConvertOnTheSpotViewProps,
} from './startCampaignModels';
import {
  campaignMessageTypesConfig,
  campaignViewValues,
} from '../../../config/CampaignsConfig';
import { useAppData } from '../../../context/AppContext';
import {
  generateMessageByCampaignById,
  updateCampaign,
} from '../../../core/requests/requests';
import { InfoModal } from '../../../components';
import SelectPhoneNumbersModal from './SelectPhoneNumbersModal';

const ConvertOnTheSpotView: React.FC<ConvertOnTheSpotViewProps> = ({
  setCurrentMessageType,
}) => {
  const {
    setCurrentStartCampaignView,
    currentCampaign,
    setCurrentCampaign,
    setShowModalBackground,
  } = useAppData();

  const [message, setMessage] = useState<string>('');
  const [generatedMessage, setGeneratedMessage] = useState<string>('');
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [isAiCheckboxChecked, setIsAiCheckboxChecked] = useState<boolean>(true);
  const [isAiIncluded, setIsAiIncluded] = useState<boolean>(true);
  const [generatingMessage, setGeneratingMessage] = useState<boolean>(false);
  const [showSelectPhoneNumberModal, setShowSelectPhoneNumberModal] =
    useState<boolean>(false);

  const currentCampaignId = currentCampaign?.id;
  const messageType = campaignMessageTypesConfig?.convertOnTheSpotType;

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
    setShowModalBackground(false);
  };

  const handleOpenInfoModal = () => {
    setShowInfoModal(true);
    setShowModalBackground(true);
  };

  const handleCheckGenerateMessage = () => {
    setIsAiCheckboxChecked(!isAiCheckboxChecked);
    setMessage('');
  };

  const handleCheckIncludeAi = () => {
    setIsAiIncluded(!isAiIncluded);
    setMessage('');
  };

  const handleChangeView = () => {
    setCurrentMessageType(messageType);
    setCurrentStartCampaignView(campaignViewValues?.chooseMedia);
  };

  const handleClearMessage = () => {
    setMessage('');
  };

  const handleShowSelectPhoneNumberModal = () => {
    setShowModalBackground(true);
    setShowSelectPhoneNumberModal(true);
  };

  const handleCloseSelectPhoneNumberModal = () => {
    setShowSelectPhoneNumberModal(false);
    setShowModalBackground(false);
  };

  const handleSendMessage = async () => {
    try {
      if (currentCampaignId) {
        const campaignPayload: CampaignDataProps = {
          ...currentCampaign,
          initialMessage: message ? message : null,
          type: 'CONVERT_ON_THE_SPOT',
        };
        const { data } = await updateCampaign(campaignPayload);
        if (data) {
          setCurrentCampaign(data);
          handleShowSelectPhoneNumberModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendInitialMessage = () => {
    // Check if message is empty and "Generate initial message via AI" checkbox is unchecked
    if (!message && !isAiCheckboxChecked) {
      handleOpenInfoModal(); // Open modal if conditions are met
    } else {
      handleSendMessage(); // Proceed with the original logic
      //handleChangeView();
      handleClearMessage();
    }
  };

  const handleGenerateInitialMessage = async () => {
    try {
      if (currentCampaignId && isAiCheckboxChecked) {
        setGeneratingMessage(true);
        const { data } = await generateMessageByCampaignById(currentCampaignId);
        if (data) {
          setGeneratedMessage(data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGeneratingMessage(false);
    }
  };

  return (
    <>
      <div className="d-flex-start-start-row pt-1_75rem w-100 mb-1_5rem">
        <div className="d-flex-start-start w-65">
          <span className="text-inter-medium pb-0_5rem">
            Optional Initial Message
          </span>
          <div className="d-flex-row w-100">
            <div className="d-flex-row mr-0_625rem w-100 p-relative">
              <input
                placeholder="Input text"
                className="campaign-convert-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={isAiCheckboxChecked}
              />
              {message && (
                <div
                  className="d-flex icon-hovered p-absolute right-1rem"
                  onClick={handleClearMessage}
                >
                  <CrossIcon />
                </div>
              )}
            </div>
            {/*<button className="btn-convert-secondary mr-0_625rem">
              + Add Dialog
            </button>*/}
            <button
              className="btn-convert-primary mr-1_5rem"
              onClick={handleSendInitialMessage}
            >
              Start
            </button>
          </div>

          {/* AI Checkboxes */}
          <div className="d-flex-row mt-1rem">
            <input
              type="checkbox"
              className="checkbox-input mr-0_625rem"
              checked={isAiIncluded}
              onChange={handleCheckIncludeAi}
            />
            <span className="text-inter-medium">Include AI for follow up</span>
          </div>
          <div className="d-flex-row mt-0_2rem">
            <input
              type="checkbox"
              className="checkbox-input mr-0_625rem"
              checked={isAiCheckboxChecked}
              onChange={handleCheckGenerateMessage} // Toggle checkbox state
            />
            <span className="text-inter-medium">
              Generate initial message via AI
            </span>
          </div>
        </div>

        {/* Message Preview Section */}
        <div className="d-flex-start-start w-35 pt-1_75rem pl-1_75rem bg-header h-100 b-radius-0_5rem">
          <div className="d-flex-start-start b-radius-0_5rem p-2_625rem bg-white w-100 b-1">
            {isAiCheckboxChecked ? (
              <>
                {generatedMessage?.length > 0 && !generatingMessage ? (
                  <div className="d-flex-start-start w-100">
                    <span>{generatedMessage}</span>
                    <div className="d-flex-center w-100">
                      <div
                        className="btn-convert-primary mt-1rem"
                        onClick={handleGenerateInitialMessage}
                      >
                        <span>Refresh</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {generatingMessage ? (
                      <div className="btn-text-preview-primary">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <div
                        className="btn-text-preview-primary"
                        onClick={handleGenerateInitialMessage}
                      >
                        <span>Preview message</span>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {message?.length > 0 ? (
                  <span>{message}</span>
                ) : (
                  <div className="btn-text-preview-primary">Preview</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showSelectPhoneNumberModal && (
        <SelectPhoneNumbersModal
          closeModal={handleCloseSelectPhoneNumberModal}
        />
      )}

      {/* Info Modal */}
      {showInfoModal ? (
        <InfoModal
          title="Please Enter an Initial Message or Enable AI Assistance"
          onClose={handleCloseInfoModal}
        />
      ) : null}
    </>
  );
};

export default ConvertOnTheSpotView;
