import React from 'react';

import { Header } from '../../layout/components';
import { SelectSubscription } from '../additionalInfo/confirmationComponents';

const AuthSelectSubscription: React.FC = () => {
  return (
    <div className="d-flex-start-start w-100">
      <Header />
      <div className="d-flex-start-start w-100 mt-8rem">
        <SelectSubscription />
      </div>
    </div>
  );
};

export default AuthSelectSubscription;
