import React, { useState } from 'react';
import Switch from 'react-switch';

import { CalendlyIcon, GoogleCalendarIcon } from '../../assets';
import { SettingsHeader } from './components';

const SettingsCalendar: React.FC = () => {
  const [calendlyChecked, setCalendlyChecked] = useState<boolean>(false);
  const [googleCalendarChecked, setGoogleCalendarChecked] =
    useState<boolean>(false);

  const handleCalendlyChange = (nextChecked: boolean) => {
    setCalendlyChecked(nextChecked);
  };

  const handleGoogleCalendarChange = (nextChecked: boolean) => {
    setGoogleCalendarChecked(nextChecked);
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <SettingsHeader />
      <div className="d-flex-start-start w-100 mt-1rem">
        <div className="d-flex-start-start mb-2_375rem">
          <span className="fw-600 c-111928 fs-1_125rem lh-1_125rem ff-poppins mb-0_5rem">
            Linked Calendars
          </span>
          <span className="c-6b7280 ff-inter">
            Connect the calendar you use in everyday life
          </span>
        </div>
        <div className="d-flex-row-space-between w-100">
          <div className="d-flex-start-start bg-white h-5_125rem w-calc-50_minus_0_5rem b-radius-0_5rem b-1 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-center">
                <CalendlyIcon />
              </div>
              <div className="d-flex-center">
                <Switch
                  onChange={handleCalendlyChange}
                  checked={calendlyChecked}
                  offColor="#E5E7EB"
                  onColor="#1E75FF"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={16}
                />
              </div>
            </div>
            <span className="ff-inter c-6b7280 fs-0_875rem">
              Link Calendly to your dashboard for easy appointment management.
            </span>
          </div>
          <div className="d-flex-start-start bg-white h-5_125rem w-calc-50_minus_0_5rem b-radius-0_5rem b-1 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-center">
                <GoogleCalendarIcon />
              </div>
              <div className="d-flex-center">
                <Switch
                  onChange={handleGoogleCalendarChange}
                  checked={googleCalendarChecked}
                  offColor="#E5E7EB"
                  onColor="#1E75FF"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={40}
                  handleDiameter={16}
                />
              </div>
            </div>
            <span className="ff-inter c-6b7280 fs-0_875rem">
              Link Calendly to your dashboard for easy appointment management.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsCalendar;
