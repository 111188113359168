import React, { useState, useEffect, useRef } from 'react';
import { useAppData } from '../../../../context/AppContext';
import { AgentProps } from '../../../../core/types/_models';
import { getAgents } from '../../../../core/requests/requests';
import { InfoIcon, RightUpArrowIcon } from '../../../../assets';
import AgentSelectionModal from '../AgentSelectionModal';
import { SelectCampaignAgentsProps } from './campaignSettingsFormModels';
import useOutsideClick from '../../../../hooks/useOutsideClicks';

const SelectCampaignAgents: React.FC<SelectCampaignAgentsProps> = ({
  formikValues,
  setFormikValues,
}) => {
  const { setShowModalBackground, currentCampaign } = useAppData();
  const [isAgentSelectionModalOpen, setIsAgentSelectionModalOpen] =
    useState(false);
  const [agentsData, setAgentsData] = useState<AgentProps[]>([]);

  const agentModalRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  const handleShowAgentSelectionModal = () => {
    setIsAgentSelectionModalOpen(true);
    setShowModalBackground(true);
  };

  const handleCloseAgentSelectionModal = () => {
    setIsAgentSelectionModalOpen(false);
    setShowModalBackground(false);
  };

  useOutsideClick(
    agentModalRef,
    () => handleCloseAgentSelectionModal(),
    excludeOutsideClickRef
  );

  const handleSetChosenAgentId = (selectedAgents: string) => {
    setFormikValues({ ...formikValues, agentId: selectedAgents });
  };

  const fetchUserAgents = async () => {
    try {
      const { data } = await getAgents();
      if (data) {
        const sortedData = data.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
        setAgentsData(sortedData);
        if (!currentCampaign?.agentId) {
          setFormikValues({ ...formikValues, agentId: sortedData[0].id });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserAgents();
  }, []);

  const selectedAgentNames = agentsData
    .filter((agent) => formikValues.agentId?.includes(agent.id))
    .map((agent) => agent.name)
    .join(', '); // Join agent names with commas

  return (
    <>
      <div className="d-flex-start-start w-100 p-relative">
        <div className="d-flex-row">
          <label className="campaign-label-input">Agent Selection</label>
          <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
            <InfoIcon />
          </div>
        </div>
        <div
          className="d-flex-row campaign-input w-100 pointer"
          onClick={handleShowAgentSelectionModal}
        >
          <span>{selectedAgentNames || 'No agents selected'}</span>
          <div className="d-flex-center w-1rem h-1rem p-absolute top-2_65rem right-0_625rem ">
            <RightUpArrowIcon className="top-2_4rem" />
          </div>
        </div>
      </div>
      {isAgentSelectionModalOpen && (
        <div className="d-flex-start-start " ref={agentModalRef}>
          <AgentSelectionModal
            onClose={handleCloseAgentSelectionModal}
            onConfirm={handleSetChosenAgentId}
            chosenAgentId={formikValues.agentId ?? ''}
            agentsData={agentsData}
          />
        </div>
      )}
    </>
  );
};

export default SelectCampaignAgents;
