import React, { useState, useEffect } from 'react';
import { AddIcon, CloseIcon, PlusIcon, TrashIcon } from '../../../assets';
import {
  addTagByLeadId,
  getAllTags,
  getLeads,
  removeTagById,
  removeTagByLeadId,
} from '../../../core/requests/requests';
import { LeadsTableProps } from './leadsModels';
import { useAuth } from '../../../context/AuthContext';
import { LeadsTagsProps } from '../../../core/types/_models';

const LeadsTable: React.FC<LeadsTableProps> = ({ leadsList, setLeadsList }) => {
  const { currentUser } = useAuth();
  const [editingLeadId, setEditingLeadId] = useState<string | null>(null);
  const [tagValue, setTagValue] = useState<string>('');
  const [availableTags, setAvailableTags] = useState<LeadsTagsProps[]>([]);
  const currentUserId = currentUser?.id;

  const handleCloseTag = () => {
    setEditingLeadId(null);
    setTagValue('');
  };

  const handleAddTagClick = (leadId: string) => {
    setTagValue('');
    setEditingLeadId(leadId);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue(e.target.value);
  };

  const fetchLeadsList = async () => {
    try {
      if (currentUserId) {
        const { data } = await getLeads(currentUserId);
        if (data) {
          setLeadsList(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllTags = async () => {
    try {
      if (currentUserId) {
        const { data } = await getAllTags(currentUserId);
        if (data) {
          setAvailableTags(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagSubmit = async (leadId: string) => {
    if (!tagValue) return;
    try {
      const { data } = await addTagByLeadId(leadId, tagValue);
      if (data) {
        fetchLeadsList();
        setEditingLeadId(null);
        setTagValue('');
        fetchAllTags();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTagRemove = async (leadId: string, tagName: string) => {
    try {
      const { data } = await removeTagByLeadId(leadId, tagName);
      if (data) {
        fetchLeadsList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApplyTag = (tag: string) => {
    setTagValue(tag);
  };

  const handleRemoveTagById = async (tagId: string) => {
    if (tagId) {
      try {
        const { data } = await removeTagById(tagId);
        if (data) {
          fetchLeadsList();
          fetchAllTags();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchAllTags();
  }, []);

  return (
    <div className="w-100 pb-2rem">
      <table className="leads-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Acquisition Date</th>
          </tr>
        </thead>
        <tbody>
          {leadsList.map((lead) => (
            <tr key={lead.id}>
              <td>
                <div className="d-flex-row">
                  <div className="d-flex-start-start">
                    <span className="leads-table-name">
                      {`${lead.firstname} ${lead.lastname}`}
                    </span>
                    {editingLeadId === lead.id ? (
                      <div className="d-flex-row ">
                        <div className="d-flex-center p-relative">
                          <input
                            type="text"
                            value={tagValue}
                            className="b-1 p-0_25rem-1rem fs-0_75rem b-radius-0_25rem"
                            onChange={handleInputChange}
                            //onBlur={() => handleTagSubmit(lead.id)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleTagSubmit(lead.id);
                              }
                            }}
                            autoFocus
                          />
                          {availableTags?.length > 0 && (
                            <div className="d-flex-start-start p-absolute left-0 w-100 bg-white top-1_5rem max-h-25rem overflow-auto shadow-box-8">
                              {availableTags?.map((tag) => (
                                <div
                                  className="d-flex-row-space-between p-0_5rem-1rem hovered bb-1 w-100 p-relative"
                                  key={tag?.id}
                                >
                                  <div
                                    className="d-flex-row w-calc-100_minus_1rem "
                                    onClick={() => handleApplyTag(tag?.name)}
                                  >
                                    <span>{tag?.name}</span>
                                  </div>
                                  <div
                                    className="d-flex-center icon-hovered w-1rem h-1rem p-absolute right-0"
                                    onClick={() => handleRemoveTagById(tag?.id)}
                                  >
                                    <TrashIcon className="w-0_75rem h-0_75rem" />
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="d-flex-row ml-0_5rem">
                          <div
                            className=" p-0_188rem-1rem bg-1e75ff b-radius-0_25rem hovered mr-0_5rem"
                            onClick={() => handleTagSubmit(lead.id)}
                          >
                            <span className="color-white fw-500">Add tag</span>
                          </div>
                          <div
                            className="d-flex-center icon-hovered b-1 b-radius-0_25rem"
                            onClick={handleCloseTag}
                          >
                            <CloseIcon className="w-1_4rem h-1_4rem" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex-row mt-0_5rem">
                        <button
                          className="add-tag-button"
                          onClick={() => handleAddTagClick(lead.id)}
                        >
                          <PlusIcon className="w-0_75rem" />
                          <span className="ff-roboto c-0062ff fs-0_875rem ml-0_5rem fw-500">
                            Add Tag
                          </span>
                        </button>
                        <div className="d-flex-row ">
                          {lead?.Tags?.map((tag) => (
                            <button
                              className="tag-button ml-0_5rem"
                              key={tag?.id}
                            >
                              <span className="ff-inter c-111928 fs-0_75rem fw-500">
                                {tag?.name}
                              </span>
                              <div
                                className="d-flex-center icon-hovered ml-0_25rem"
                                onClick={() =>
                                  handleTagRemove(lead.id, tag?.name)
                                }
                              >
                                <CloseIcon className="w-1rem h-1rem" />
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>{lead.email}</td>
              <td>{lead.phone || 'N/A'}</td>
              <td>{new Date(lead.createdAt).toLocaleDateString()}</td>
              {/*<td>{lead.company}</td>
              <td>
                <div className="d-flex-center icon-hovered">
                  <AddIcon />
                </div>
              </td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeadsTable;
