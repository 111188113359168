import React, { useState, useEffect } from 'react';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { SubscriptionsPlansProps } from '../../../core/types/_models';
import { getSubscriptionPlans } from '../../../core/requests/requests';
import { SelectSubscriptionProps } from './confirmationComponentsModels';

const SelectSubscription: React.FC<SelectSubscriptionProps> = ({
  isUpdating,
  closeSubscriptionModal,
}) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionsPlansProps[]
  >([]);

  // Utility function to group plans by type
  const groupPlansByType = (plans: SubscriptionsPlansProps[]) => {
    return plans.reduce(
      (acc, plan) => {
        if (!acc[plan.type]) {
          acc[plan.type] = [];
        }
        acc[plan.type].push(plan);
        return acc;
      },
      {} as Record<string, SubscriptionsPlansProps[]>
    );
  };

  useEffect(() => {
    const handleGetPaymentMethods = async () => {
      try {
        const { data } = await getSubscriptionPlans();
        if (data) {
          setSubscriptionPlans(data);
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    handleGetPaymentMethods();
  }, []);

  const groupedPlans = groupPlansByType(subscriptionPlans);

  // Define the custom order
  const customOrder = ['Free', 'Basic', 'Pro'];

  // Sort the grouped plans by the custom order
  const sortedGroupedPlans = Object.entries(groupedPlans).sort(
    ([a], [b]) => customOrder.indexOf(a) - customOrder.indexOf(b)
  );

  return (
    <div className="d-flex-center-start-row w-100 f-wrap">
      {sortedGroupedPlans.length > 0 ? (
        sortedGroupedPlans.map(([type, plans]) => (
          <SubscriptionPlanCard
            key={type}
            subscriptionPlans={plans}
            isUpdating={isUpdating}
            closeSubscriptionModal={closeSubscriptionModal}
          />
        ))
      ) : (
        <div className="d-flex-center p-2rem w-100">
          <span className="f-roboto-secondary">
            No subscription plans available.
          </span>
        </div>
      )}
    </div>
  );
};

export default SelectSubscription;
