import {
  CampaignTimeZone,
  CampaignTimeZonesDropdownConfigProps,
} from '../config/CampaignsConfig';

export const extractTimeZoneValue = (
  config: CampaignTimeZonesDropdownConfigProps,
  value: number
): string => {
  const matchingTimeZone = config.timeZones.find(
    (timeZone: CampaignTimeZone) => timeZone.value === value
  );

  return matchingTimeZone ? matchingTimeZone.timeZone : 'UTC+0';
};

export const extractInfoInParentheses = (value: string) => {
  const matches = value.match(/\((.*?)\)/);
  if (matches && matches.length > 1) {
    return `(${matches[1]})`;
  } else {
    return null;
  }
};

export const getFormattedStringFromDate = (date: Date | null) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const convertTimeTo12HourFormat = (timeString: string) => {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return formattedTime;
};

export function isAM(timeString: string) {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();

  return hours < 12;
}

export const getFormatTimeWithoutPeriod = (timeString: string) => {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
};

export const getFileCharacterCount = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileContent = reader.result as string;
      resolve(fileContent.length); // Return the length of the file content as character count
    };

    reader.onerror = (error) => {
      reject('Error reading file content');
    };

    // Read file as text
    reader.readAsText(file);
  });
};
