import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { SubscriptionPlanCardProps } from './confirmationComponentsModels';
import {
  getUser,
  selectSubscriptionPlan,
  updateSubscriptionById,
  updateUser,
} from '../../../core/requests/requests';
import { CheckedRoundedIcon, StarIcon } from '../../../assets';
import { calculateYearlySavings } from '../../../helpers/subscriptionHelpers';
import { useAuth } from '../../../context/AuthContext';
import AddPaymentMethodModal from '../../../components/modal/AddPaymentMethodModal';
import { useAppData } from '../../../context/AppContext';
import { ConfirmModal } from '../../../components';

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  subscriptionPlans,
  isUpdating,
  closeSubscriptionModal,
}) => {
  const navigate = useNavigate();
  const { setShowModalBackground } = useAppData();
  const { currentUser, authLogin, setCurrentUser } = useAuth();
  const [selectedInterval, setSelectedInterval] = useState<'month' | 'year'>(
    'month'
  );
  const [selectedSubscriptionPlanId, setSelectedSubscriptionPlanId] =
    useState<string>('');
  const [showAddPaymentMethodModal, setShowOpenAddPaymentMethodModal] =
    useState<boolean>(false);
  const [showUpdateSubscriptionPlanModal, setShowUpdateSubscriptionPlanModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userId = authLogin?.userId;
  const accessToken = authLogin?.accessToken;

  const handleShowUpdateSubscriptionPlanModal = (selectedPlanId: string) => {
    setShowUpdateSubscriptionPlanModal(true);
    setShowModalBackground(true);
    setSelectedSubscriptionPlanId(selectedPlanId);
  };

  const handleCloseUpdateSubscriptionPlanModal = () => {
    setShowUpdateSubscriptionPlanModal(false);
    setShowModalBackground(false);
    setSelectedSubscriptionPlanId('');
  };
  const handleShowAddPaymentMethodModal = () => {
    setShowOpenAddPaymentMethodModal(true);
  };

  const handleHideAddPaymentMethodModal = () => {
    setShowOpenAddPaymentMethodModal(false);
  };

  const handleUpdateSubscriptionPlan = async () => {
    setIsLoading(true);
    try {
      if (selectedSubscriptionPlanId) {
        const { data } = await updateSubscriptionById(
          selectedSubscriptionPlanId
        );
        if (data) {
          window.open(data.invoiceHosted, '_blank');
          if (userId && accessToken) {
            const response = await getUser(userId, accessToken);
            if (response.data) {
              setCurrentUser(response.data);
              handleCloseUpdateSubscriptionPlanModal();
              setIsLoading(false);
              if (closeSubscriptionModal) {
                closeSubscriptionModal();
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleFinishSetup = async () => {
    try {
      const updateUserPayload = {
        id: userId ? userId : '',
        email: currentUser?.email ? currentUser?.email : '',
        firstName: currentUser?.firstName ? currentUser?.firstName : '',
        askAdditionalInfo: false,
      };

      if (accessToken && userId) {
        const { data } = await updateUser(
          accessToken,
          userId,
          updateUserPayload
        );

        if (data) {
          const response = await getUser(userId, accessToken);
          if (response.data) {
            setCurrentUser(response.data);
            if (closeSubscriptionModal) {
              closeSubscriptionModal();
            }
            handleHideAddPaymentMethodModal();
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      subscriptionPlans[0]?.type === 'Free'
        ? navigate('/settings/payments')
        : navigate('/');
    }
  };

  // Determine the current plan based on conditions
  const currentPlan =
    subscriptionPlans?.length === 1 || subscriptionPlans[0]?.type === 'Free'
      ? subscriptionPlans[0]
      : subscriptionPlans.find((plan) => plan.interval === selectedInterval);

  const handleConfirmSubscriptionPlan = async (subscriptionPlanId: string) => {
    try {
      const { data } = await selectSubscriptionPlan(subscriptionPlanId);
      if (data) {
        window.open(data.invoiceHosted, '_blank');
        if (!currentUser?.askAdditionalInfo) {
          const response = await getUser(userId!, accessToken!);
          if (response?.data) {
            setCurrentUser(response?.data);
            setShowModalBackground(false);
          }
        } else {
          handleFinishSetup();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectSubscriptionPlan = (subscriptionPlanId: string) => {
    setSelectedSubscriptionPlanId(subscriptionPlanId);
    if (currentUser?.subscription) {
      handleConfirmSubscriptionPlan(subscriptionPlanId);
    } else {
      handleShowAddPaymentMethodModal();
    }
  };

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

  return (
    <Elements stripe={stripePromise}>
      <div className="subscriptions-plan-card">
        <div className="subscriptions-plan-buttons">
          {currentPlan?.type === 'Free' ? (
            // Render only the container without buttons if the plan is "Free"
            <span></span>
          ) : (
            <>
              <button
                className={`select-subscription-plan-button ${
                  selectedInterval === 'month' ? 'active' : ''
                }`}
                onClick={() => setSelectedInterval('month')}
              >
                Monthly
              </button>
              <div className="yearly-subscription-plan-button">
                <button
                  className={`select-subscription-plan-button w-100 ${
                    selectedInterval === 'year' ? 'active' : ''
                  }`}
                  onClick={() => setSelectedInterval('year')}
                >
                  Yearly
                </button>
                {selectedInterval !== 'year' && (
                  <div className="yearly-discount-message">
                    <div className="yearly-discount-message-icon">
                      <StarIcon />
                    </div>
                    <span className="yearly-discount-message-text">
                      {`Save $${calculateYearlySavings(subscriptionPlans)}`}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {currentPlan ? (
          <>
            <span className="subscriptions-plan-name">{currentPlan.name}</span>
            <span className="subscriptions-plan-description">
              {currentPlan.description}
            </span>
            <span className="subscriptions-plan-price-value">
              {`$${currentPlan.price}`}
              <span className="subscriptions-plan-price">
                {`/ per ${currentPlan.interval}`}
              </span>
            </span>
            <button
              className="subscriptions-plan-button"
              onClick={() =>
                isUpdating
                  ? handleShowUpdateSubscriptionPlanModal(currentPlan.id)
                  : handleSelectSubscriptionPlan(currentPlan.id)
              }
            >
              Get Access
            </button>
            <span className="subscriptions-plan-agent-description">
              Your Agent will generate upon successful payment.
            </span>
            <div className="subscriptions-plan-features">
              {currentPlan.features.map((feature, index) => (
                <div key={index} className="subscriptions-plan-feature-row">
                  <div className="subscriptions-plan-feature-icon">
                    <CheckedRoundedIcon />
                  </div>
                  <span className="subscriptions-plan-feature-description">
                    {feature?.description}
                  </span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <span className="subscriptions-plan-error">
            No subscription plan available for the selected interval.
          </span>
        )}
      </div>
      {showAddPaymentMethodModal && (
        <div className="d-flex-center p-fixed top-0 bg-modal left-0 size-max z4">
          <AddPaymentMethodModal
            closeModal={handleHideAddPaymentMethodModal}
            selectedSubscriptionPlanId={selectedSubscriptionPlanId}
            handleFinishSetup={handleFinishSetup}
          />
        </div>
      )}
      {showUpdateSubscriptionPlanModal && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to update subscription plan?"
            onConfirm={handleUpdateSubscriptionPlan}
            onCancel={handleCloseUpdateSubscriptionPlanModal}
            isLoading={isLoading}
          />
        </div>
      )}
    </Elements>
  );
};

export default SubscriptionPlanCard;
