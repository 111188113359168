import React from 'react';
import {
  HUBSPOT_CLIENT_ID,
  HUBSPOT_REDIRECT_URI,
} from '../../../helpers/envProcessor';

const SyncLeadsButton: React.FC = () => {
  const handleSyncLeads = () => {
    try {
      if (HUBSPOT_CLIENT_ID && HUBSPOT_REDIRECT_URI) {
        const authUrl =
          'https://app-eu1.hubspot.com/oauth/authorize' +
          `?client_id=${encodeURIComponent(HUBSPOT_CLIENT_ID)}` +
          `&scope=crm.lists.read%20crm.objects.contacts.read%20crm.schemas.contacts.read` +
          `&redirect_uri=${encodeURIComponent(HUBSPOT_REDIRECT_URI)}`;

        console.log(authUrl); // Log the auth URL for debugging
        window.open(authUrl, '_blank', 'noopener,noreferrer'); // Open in a new tab
      } else {
        console.error('HubSpot Client ID or Redirect URI is missing.');
      }
    } catch (error) {
      console.error(
        'An unexpected error occurred while trying to sync leads:',
        error
      );
    }
  };

  return (
    <div className="d-flex-center ml-1rem">
      <button className="btn-import-leads fw-600" onClick={handleSyncLeads}>
        <span>Sync Leads</span>
      </button>
    </div>
  );
};

export default SyncLeadsButton;
