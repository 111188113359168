export function formatStatisticValue(num: number | null | undefined): string {
  // Handle null or undefined values
  if (num === null || num === undefined) {
    return '0'; // Return '0' or any other default value as needed
  }

  if (num < 1000) {
    // Round to 2 decimal places if the number has more than 2 decimal places
    const roundedToTwoDecimals = Math.round(num * 100) / 100;
    return roundedToTwoDecimals.toString();
  } else {
    // Divide by 1000 and round to 1 decimal place
    const roundedNum = (num / 1000).toFixed(1);

    // Remove trailing .0 if it's an integer after rounding
    return `${roundedNum.endsWith('.0') ? parseInt(roundedNum) : roundedNum}k`;
  }
}

export function calculateStatisticsPercentage(
  value: number | null | undefined,
  prevValue: number | null | undefined
) {
  // Handle the case where either value is null or undefined
  if (
    value === null ||
    value === undefined ||
    prevValue === null ||
    prevValue === undefined
  ) {
    return '0%'; // Return 0% if either value is null or undefined
  }

  // Handle the case where previous value is zero
  if (prevValue === 0) {
    return value > 0 ? '100%' : '0%'; // If there are leads now, consider it a 100% increase; otherwise, it's 0%
  }

  const changeRate = ((value - prevValue) / prevValue) * 100; // Calculate the change rate
  return `${Math.round(changeRate)}%`;
}

export function truncateStringWithMaxLength(
  value: string,
  maxLength: number
): string {
  if (value.length <= maxLength) {
    return value;
  }

  const truncated = value.slice(0, maxLength);
  return `${truncated}...`;
}

export const capitalizeFirstLetter = (input: string): string => {
  if (!input) return input; // Handle empty or null strings
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);

  // Use Intl.DateTimeFormat to format the date
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short', // 'short' will give the 3-letter month abbreviation
    day: '2-digit', // '2-digit' ensures the day is formatted with leading zero if needed
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
};
