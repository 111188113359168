import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Switch from 'react-switch';

import { addPaymentMethod } from '../../../core/requests/requests';
import { PlusAddIcon } from '../../../assets';
import { AddPaymentMethodButtonProps } from './paymentsModels';

const AddPaymentMethodButton: React.FC<AddPaymentMethodButtonProps> = ({
  fetchPaymentMethods,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showCardForm, setShowCardForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentMethodByDefault, setPaymentMethodByDefault] =
    useState<boolean>(true);
  const [paymentMethodName, setPaymentMethodName] = useState<string>('');

  const handleSetPaymentMethodByDefault = (nextChecked: boolean) => {
    setPaymentMethodByDefault(nextChecked);
  };

  const handleChangePaymentMethodName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethodName(e.target.value);
  };
  const handleShowCardForm = () => {
    setShowCardForm(true);
  };

  const handleAddCard = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);
    try {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error('CardElement not found');
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setErrorMessage('Error creating payment method');
        setIsProcessing(false);
        return;
      }

      if (paymentMethod) {
        const response = await addPaymentMethod(
          paymentMethod.id,
          paymentMethodByDefault,
          paymentMethodName
        );
        if (response?.status === 200) {
          fetchPaymentMethods();
          setShowCardForm(false);
          setIsProcessing(false);
          setErrorMessage('');
        }
      }
    } catch (err) {
      setErrorMessage('Error creating payment method');
      setIsProcessing(false);
    }
  };

  return (
    <div className="d-flex-start-start w-100">
      {showCardForm ? (
        <div className="add-payment-method">
          <div className="d-flex-row-space-between w-100 pb-1_5rem">
            <input
              placeholder="payment method name"
              className="payment-details-input w-calc-100_minus_10rem"
              onChange={handleChangePaymentMethodName}
            />
            <div className="d-flex-row">
              <span className="mr-0_5rem ff-inter c-6b7280 fw-500 fs-0_875rem">
                Set as default
              </span>
              <Switch
                onChange={handleSetPaymentMethodByDefault}
                checked={paymentMethodByDefault}
                offColor="#E5E7EB"
                onColor="#1E75FF"
                checkedIcon={false}
                uncheckedIcon={false}
                height={20}
                width={40}
                handleDiameter={16}
              />
            </div>
          </div>
          <CardElement className="w-100" />{' '}
        </div>
      ) : null}
      {showCardForm ? (
        <button
          className="btn-subscriptions-secondary w-100 mb-1_5rem"
          onClick={handleAddCard}
        >
          <div className="d-flex-center mr-0_5rem">
            {!isProcessing && <PlusAddIcon />}
          </div>
          <span>{isProcessing ? 'Processing...' : 'Add payment method'}</span>
          {errorMessage && (
            <span className="add-payment-method-error-message">
              {errorMessage}
            </span>
          )}
        </button>
      ) : (
        <button
          className="btn-subscriptions-secondary w-100 mb-1_5rem"
          onClick={handleShowCardForm}
        >
          <div className="d-flex-center mr-0_5rem">
            <PlusAddIcon />
          </div>
          <span>Add new payment method</span>
        </button>
      )}
    </div>
  );
};

export default AddPaymentMethodButton;
