import React from 'react';
import { MasterCardIcon, PayPalIcon, VisaIcon } from '../../../assets';
import { PaymentMethodIconProps } from './paymentsModels';

const PaymentMethodIcon: React.FC<PaymentMethodIconProps> = ({
  paymentMethod,
}) => {
  switch (
    paymentMethod.toLowerCase() // Ensure case-insensitive matching
  ) {
    case 'visa':
      return (
        <div className="d-flex-center">
          <VisaIcon />
        </div>
      );
    case 'master-card':
      return (
        <div className="d-flex-center">
          <MasterCardIcon />
        </div>
      );
    case 'paypal':
      return (
        <div className="d-flex-center">
          <PayPalIcon />
        </div>
      );
    default:
      return null; // Return null or a default icon if the value is not recognized
  }
};

export default PaymentMethodIcon;
