import React from 'react';
import { useNavigate } from 'react-router-dom';

const DynamicHeader: React.FC<{ progress: number }> = ({ progress }) => {
  const navigate = useNavigate();
  const steps = [
    { label: '1. Introduction', link: '/introduction', progressRequired: 1 },
    { label: '2. Conversation', link: '/conversation', progressRequired: 2 },
    {
      label: '3. More About You',
      link: '/additional-info',
      progressRequired: 3,
    },
    {
      label: '4. Confirmation',
      link: '/additional-business-info',
      progressRequired: 4,
    },
  ];

  const handleClickLink = (link: string, progressRequired: number) => {
    if (progress >= progressRequired) {
      navigate(link);
    }
  };

  return (
    <div className="d-flex-center w-100 pt-3_25rem p-absolute top-0 z1">
      <div className="d-flex-start-between-row w-75 h-2rem">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`${progress > step.progressRequired ? 'hovered' : ''} d-flex-center-start w-21 p-relative`}
            onClick={() => handleClickLink(step.link, step.progressRequired)}
          >
            <div className="progress-bar mb-0_75rem"></div>
            <div
              className="progress-bar-active mb-0_75rem p-absolute top-0"
              style={{
                width:
                  progress > step.progressRequired
                    ? '100%'
                    : progress === step.progressRequired
                      ? '50%'
                      : '0%',
                transition: 'width 0.5s',
              }}
            ></div>
            {progress > step.progressRequired ? (
              <span className="dynamic-header-active-text">{step.label}</span>
            ) : (
              <span className="dynamic-header-text">{step.label}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicHeader;
