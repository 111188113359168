import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import SocialMediaConnections from './components/SocialMediaConnections';
import { BusinessProps, UserProfileProps } from '../../core/types/_models';
import { useAuth } from '../../context/AuthContext';
import { ExportIcon } from '../../assets';
import {
  addressValidationSchema,
  campaignLinkValidationSchema,
  cityValidationSchema,
  companyNameValidationSchema,
  countryValidationSchema,
  linkedinLinkValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import { getUser, updateUser } from '../../core/requests/requests';

const AdditionaBusinessInfoPage: React.FC<{
  setProgress: (value: number) => void;
}> = ({ setProgress }) => {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser, authLogin } = useAuth();
  const [focusedFields, setFocusedFields] = useState({
    companyName: false,
    country: false,
    address: false,
    linkedinUrl: false,
    about: false,
  });
  //const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setProgress(4);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [setProgress]);

  const initialValues = {
    id: currentUser?.id ?? '',
    email: currentUser?.email ?? '',
    firstName: currentUser?.firstName ?? '',
    companyName: currentUser?.companyName ?? '',
    country: currentUser?.country ?? '',
    address: currentUser?.address ?? '',
    linkedinUrl: currentUser?.linkedinUrl ?? '',
    about: currentUser?.about ?? '',
  };

  const validationSchema = Yup.object().shape({
    companyName: companyNameValidationSchema,
    country: countryValidationSchema,
    address: addressValidationSchema,
    linkedinUrl: linkedinLinkValidationSchema,
  });

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleNavigateToConfirmationPage = () => {
    navigate('/confirmation');
  };

  const handleSubmit = async (values: UserProfileProps) => {
    try {
      const userId = currentUser?.id;
      const accessToken = authLogin?.accessToken;
      if (userId && accessToken) {
        const { data } = await updateUser(accessToken, userId, values);
        if (data) {
          if (data) {
            const response = await getUser(userId, accessToken);
            setCurrentUser(response.data);
            handleNavigateToConfirmationPage();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Handle the selected files
      console.log(files);
      // You can perform any file upload operations here
    }
  };*/

  return (
    <div className="d-flex-center w-75 p-relative pb-5rem">
      <div className="d-flex-start-start b-radius-3rem bg-white w-100 mt-8rem p-3_125rem-4_125rem bg-white shadow-box-bottom z1">
        <h3 className="h3-primary mb-1rem ">More about the business</h3>
        <p className="p-description mb-2rem">
          Feel free to edit anything that isn't quite right.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, errors }) => (
            <Form className="w-100">
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="companyName"
                    className={`form-control ${errors.companyName ? 'error-border' : ''} `}
                    placeholder="Company name"
                    onFocus={() => handleFieldFocus('companyName')}
                    onBlur={() => handleFieldBlur('companyName')}
                  />
                  {errors.companyName && (
                    <div className="form-error">{errors.companyName}</div>
                  )}
                  {focusedFields.companyName && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.companyName ? 'error-title' : 'correct-title'}`}
                      >
                        Company
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="country"
                    className={`form-control ${errors.country ? 'error-border' : ''} `}
                    placeholder="Country"
                    onFocus={() => handleFieldFocus('country')}
                    onBlur={() => handleFieldBlur('country')}
                  />
                  {errors.country && (
                    <div className="form-error">{errors.country}</div>
                  )}
                  {focusedFields.country && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.country ? 'error-title' : 'correct-title'}`}
                      >
                        Country
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="address"
                    className={`form-control ${errors.address ? 'error-border' : ''} `}
                    placeholder="Address"
                    onFocus={() => handleFieldFocus('address')}
                    onBlur={() => handleFieldBlur('address')}
                  />
                  {errors.address && (
                    <div className="form-error">{errors.address}</div>
                  )}
                  {focusedFields.address && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.address ? 'error-title' : 'correct-title'}`}
                      >
                        Address
                      </span>
                    </div>
                  )}
                </div>
                <div className="form-item  w-calc-50_minus_0_875rem">
                  <Field
                    type="text"
                    name="linkedinUrl"
                    className={`form-control ${errors.linkedinUrl ? 'error-border' : ''} `}
                    placeholder="LinkedIn URL"
                    onFocus={() => handleFieldFocus('linkedinUrl')}
                    onBlur={() => handleFieldBlur('linkedinUrl')}
                  />
                  {errors.linkedinUrl && (
                    <div className="form-error">{errors.linkedinUrl}</div>
                  )}
                  {focusedFields.linkedinUrl && (
                    <div className="d-flex-center title-container">
                      <span
                        className={`form-title ${errors.linkedinUrl ? 'error-title' : 'correct-title'}`}
                      >
                        Linkedin
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 mb-1rem">
                <span className="fs-0_875rem">Tell us about your business</span>
                <div className="form-item  w-100 mt-0_75rem">
                  <Field
                    type="text"
                    name="about"
                    component="textarea"
                    className="textarea-control"
                    placeholder="About"
                    onFocus={() => handleFieldFocus('about')}
                    onBlur={() => handleFieldBlur('about')}
                  />
                  {/*focusedFields.about && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Hello my name...
                    </span>
                  </div>
                )*/}
                </div>
              </div>
              {/*<div className="w-100 mb-2_375rem">
              <span className="fs-0_875rem">Give us more information</span>
              <div className="form-item  w-100 mt-0_75rem">
                <Field
                  type="text"
                  name="moreInfo"
                  component="textarea"
                  className="textarea-control"
                  placeholder="Our firm first opened in 1989 and our founders are Bill and Mary Hugets"
                  onFocus={() => handleFieldFocus('moreInfo')}
                  onBlur={() => handleFieldBlur('moreInfo')}
                />
                focusedFields.about && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Hello my name...
                    </span>
                  </div>
                )
              </div>
            </div>*/}
              {/*<div className="d-flex-start-start w-100">
                <div
                  className="d-flex-row w-100 mb-2_375rem hovered"
                  onClick={handleDivClick}
                >
                  <div className="d-flex-center mr-0_5rem">
                    <ExportIcon />
                  </div>
                  <span className="ff-inter fw-500 c-1751d0">Import Files</span>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div>*/}
              {/*<SocialMediaConnections />*/}
              <button
                type="submit"
                className="btn btn-light-primary w-100"
                disabled={!isValid}
              >
                Final Step
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="d-flex-start-start p-absolute left-5rem bottom-2rem shadow-box-bottom bg-white h-4rem bbl-radius-1_25rem bbr-radius-1_25rem w-50">
        <div className="w-100 h-1rem"></div>
        <div className="d-flex-center w-100 h-3rem z1">
          <Link to="/auth/login" className="primary-link">
            Already have an account? Log in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdditionaBusinessInfoPage;
