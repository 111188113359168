import React from 'react';

import { SearchIcon } from '../../../assets';
//import { LeadsActionDropdownConfig } from '../../../config/LeadsConfig';
import { CampaignsSearchWithDropdownProps } from '../../startCampaign/components/startCampaignModels';

const CampaignsSearchWithDropdown: React.FC<
  CampaignsSearchWithDropdownProps
> = ({ campaignsPageState, setCampaignsPageState }) => {
  //const [showActionDropdown, setShowActionDropdown] = useState(false);

  return (
    <div className="d-flex-start-start p-0_625rem w-100 mb-1_25rem">
      <div className="d-flex-row-space-between w-100">
        <div className="d-flex-start-start w-100 p-relative">
          <input
            type="text"
            className="leads-search-input"
            placeholder="Search by Company"
            value={campaignsPageState?.searchQuery}
            onChange={(e) =>
              setCampaignsPageState((prevState) => ({
                ...prevState,
                searchQuery: e.target.value,
              }))
            }
          />
          <SearchIcon className="p-absolute t-0_437rem left-0_562rem" />
        </div>
        {/*<div
          className="d-flex-start-start pl-1_25rem"
          onClick={() => setShowActionDropdown(!showActionDropdown)}
        >
          <div className="d-flex-row-space-between w-5_875rem h-2_375rem b-1 b-radius-0_75rem p-0-0_625rem p-relative pointer">
            <span className="f-roboto-primary fs-0_875rem">
              {campaignsPageState?.actionDropdown}
            </span>
            <TriangleIcon
              className={`${showActionDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
            />
            {showActionDropdown && (
              <div className="p-absolute top-2_3rem z1 bg-white w-100 left-0 ">
                {LeadsActionDropdownConfig.actionDropdown.map(
                  (option, index) => (
                    <div
                      className="d-flex-center-start h-2_375rem bb-1 hovered"
                      key={index}
                      onClick={() => {
                        setCampaignsPageState((prevState) => ({
                          ...prevState,
                          actionDropdown: option,
                        }));
                        setShowActionDropdown(false);
                      }}
                    >
                      <span className="sort-dropdown-item pl-0_75rem">
                        {option}
                      </span>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default CampaignsSearchWithDropdown;
