import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const SettingsHeader: React.FC = () => {
  const location = useLocation();

  return (
    <div className="d-flex-row bg-white shadow-box-9 b-radius-0_5rem w-100 h-4_31rem p-1rem mb-2rem">
      <Link
        to="/settings/agent-bio"
        className={`${location.pathname === '/settings/agent-bio' ? 'active' : ''} settings-header-tab mr-1rem`}
      >
        <span>Agent Bio</span>
      </Link>
      <Link
        to="/settings/calendar"
        className={`${location.pathname === '/settings/calendar' ? 'active' : ''} settings-header-tab mr-1rem`}
      >
        <span>Calendar</span>
      </Link>
      <Link
        to="/settings/integrations"
        className={`${location.pathname === '/settings/integrations' ? 'active' : ''} settings-header-tab mr-1rem`}
      >
        <span>Integrations</span>
      </Link>
      <Link
        to="/settings/payments"
        className={`${location.pathname === '/settings/payments' ? 'active' : ''} settings-header-tab`}
      >
        <span>Payment & subscriptions</span>
      </Link>
    </div>
  );
};

export default SettingsHeader;
