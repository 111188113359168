import React, { useState } from 'react';

import { PaymentMethodCardComponentProps } from './paymentsModels';
import { capitalizeFirstLetter } from '../../../helpers/displayDataHelpers';
import { ConfirmModal } from '../../../components';
import { useAppData } from '../../../context/AppContext';
import { deletePaymentMethodById } from '../../../core/requests/requests';
import { PaymentMethodIcon } from './';

const PaymentMethodCard: React.FC<PaymentMethodCardComponentProps> = ({
  paymentMethod,
  activePaymentMethodId,
  setActivePaymentMethodId,
  fetchPaymentMethods,
}) => {
  const { setShowModalBackground } = useAppData();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleShowConfirmDeleteModal = () => {
    setShowModalBackground(true);
    setShowConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setShowModalBackground(false);
  };

  const handleChangeActiveMethodId = (id: string) => {
    setActivePaymentMethodId(id);
  };

  const handleDeletePaymentMethod = async () => {
    try {
      const response = await deletePaymentMethodById(paymentMethod.id);
      if (response?.status === 204) {
        fetchPaymentMethods();
        handleCloseConfirmDeleteModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`${paymentMethod.id === activePaymentMethodId ? 'active' : ''} payment-method-item`}
        onClick={() => handleChangeActiveMethodId(paymentMethod.id)}
      >
        <div className="payment-method-info">
          <div className="payment-method-checkbox-row">
            <div className="payment-method-checkbox"></div>
            <div className="d-flex-start-start mb-1rem">
              <span className="ff-inter c-111928 fw-500 pb-0_375rem lh-1rem">
                {`${capitalizeFirstLetter(paymentMethod?.name)} ending in ${paymentMethod?.card?.last4}`}
              </span>
              <span className="c-6b7280 fs-0_75rem">{`Expiry ${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}`}</span>
            </div>
          </div>
          <div className="d-flex-row">
            <div
              className="d-flex-center br-1 hovered pr-0_5rem"
              onClick={handleShowConfirmDeleteModal}
            >
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">
                Delete
              </span>
            </div>
            {/*<div className="d-flex-center  hovered pl-0_5rem">
            <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">Edit</span>
          </div>*/}
          </div>
        </div>
        <PaymentMethodIcon paymentMethod={paymentMethod?.card?.brand} />
      </div>
      {showConfirmDeleteModal && (
        <div className="d-flex-center p-fixed top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to delete this agent?"
            onConfirm={handleDeletePaymentMethod}
            onCancel={handleCloseConfirmDeleteModal}
          />
        </div>
      )}
    </>
  );
};

export default PaymentMethodCard;
