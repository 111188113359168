import React, { useEffect } from 'react';

import { SelectSubscription } from './confirmationComponents';
import ConfirmSubscription from './confirmationComponents/ConfirmSubscription';
import { ConfirmationPageProps } from './confirmationComponents/confirmationComponentsModels';

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({ setProgress }) => {
  useEffect(() => {
    setProgress(5);
  }, []);

  return (
    <div className="d-flex-start-center mt-6_5rem w-100 mb-3rem">
      {/* <ConfirmSubscription setProgress={setProgress} /> */}
      <SelectSubscription />
    </div>
  );
};

export default ConfirmationPage;
