import React, { useRef } from 'react';
import { SubscriptionPlansModalProps } from './paymentsModels';
import { SelectSubscription } from '../../additionalInfo/confirmationComponents';
import { CloseIcon } from '../../../assets';
import useOutsideClick from '../../../hooks/useOutsideClicks';

const SubscriptionPlansModal: React.FC<SubscriptionPlansModalProps> = ({
  closeModal,
}) => {
  const subscriptionPlansModalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(subscriptionPlansModalRef, () => closeModal());

  return (
    <div
      className="d-flex-start-start w-90 max-h-90vh overflow-auto bg-secondary b-radius-1_25rem p-1_5rem"
      ref={subscriptionPlansModalRef}
    >
      <div className="d-flex-row-space-between w-100 mb-1rem">
        <span className="ff-poppins c-regular lh-1_5rem fw-600 fs-1_25rem">
          Subscription Plans
        </span>
        <div className="d-flex-center icon-hovered" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <SelectSubscription
        isUpdating={true}
        closeSubscriptionModal={closeModal}
      />
    </div>
  );
};

export default SubscriptionPlansModal;
