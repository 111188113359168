import React, { useState, useRef, useEffect } from 'react';
import { Field, FormikErrors } from 'formik';

import { SelectedCountryProps } from '../../core/types/_models';
import { countryPhoneCodes } from '../../config';
import useOutsideClick from '../../hooks/useOutsideClicks';
import { RectangleDownIcon } from '../../assets';

export interface SelectCountryDropdownProps {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<{
    country: string;
  }>>;
  handleFieldFocus: (fieldName: string) => void;
  handleFieldBlur: (fieldName: string) => void;
  isCountryFocused: boolean;
  errors: FormikErrors<{
    country: string;
  }>;
  countryValue: string;
}

const SelectCountryDropdown: React.FC<SelectCountryDropdownProps> = ({
  setFieldValue,
  handleFieldFocus,
  handleFieldBlur,
  isCountryFocused,
  errors,
  countryValue,
}) => {
  const [inputCountryValue, setInputCountryValue] = useState<string>('');
  const [countryDropdownOpen, setCountryDropdownOpen] =
    useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    useState<SelectedCountryProps | null>(null);
  const [filteredCountries, setFilteredCountries] =
    useState<SelectedCountryProps[]>(countryPhoneCodes);
  const countryDropdownRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    countryDropdownRef,
    () => setCountryDropdownOpen(false),
    excludeOutsideClickRef
  );

  const filterCountries = (value: string) => {
    const filtered = countryPhoneCodes.filter(
      (country) =>
        country.country.toLowerCase().includes(value.toLowerCase()) ||
        country.code.includes(value)
    );
    setFilteredCountries(filtered);
  };

  const toggleCountryDropdown = () => {
    setCountryDropdownOpen((countryDropdownOpen) => !countryDropdownOpen);
  };

  useEffect(() => {
    const matchedCountry = countryPhoneCodes.find(
      (countryObj) => countryObj.country === inputCountryValue
    );
    if (matchedCountry) {
      setSelectedCountry(matchedCountry);
      setFieldValue('country', inputCountryValue);
    } else {
      setSelectedCountry(null);
      setFieldValue('country', '');
    }
  }, [inputCountryValue]);

  return (
    <>
      <Field
        type="text"
        name="country"
        className={`form-control ${errors.country ? 'error-border' : ''} `}
        placeholder="Your Country"
        onFocus={() => {
          handleFieldFocus('country');
          toggleCountryDropdown();
        }}
        onBlur={() => handleFieldBlur('country')}
        value={inputCountryValue || countryValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const inputCountryValue = e.target.value;
          setInputCountryValue(inputCountryValue);
          filterCountries(inputCountryValue);
        }}
        innerRef={excludeOutsideClickRef}
      />
      {countryDropdownOpen && (
        <>
          {filteredCountries.length > 0 ? (
            <div
              className="dropdown-country-menu shadow-box-12"
              ref={countryDropdownRef}
            >
              {filteredCountries.map((country, index) => (
                <div
                  key={`${country.code}-${index}`}
                  onClick={() => {
                    setInputCountryValue(country.country);
                    setCountryDropdownOpen(false);
                  }}
                  className="dropdown-country-item"
                >
                  <span>{country.country}</span>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="dropdown-country-menu shadow-box-12"
              ref={countryDropdownRef}
            >
              <div className="dropdown-country-item shadow-box-12">
                <span>There are no matching countries</span>
              </div>
            </div>
          )}
        </>
      )}
      {errors.country && <div className="form-error">{errors.country}</div>}
      <div className="country-dropdown-icon" onClick={toggleCountryDropdown}>
        <RectangleDownIcon />
      </div>
      {isCountryFocused && (
        <div className="d-flex-center title-container">
          <span
            className={`form-title ${errors.country ? 'error-title' : 'correct-title'}`}
          >
            Your Country
          </span>
        </div>
      )}
    </>
  );
};

export default SelectCountryDropdown;
