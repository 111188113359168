import React, { useState, useRef } from 'react';
import { SelectCampaignAdCampaignProps } from './campaignSettingsFormModels';
import AdCampaignSelectionModal from '../AdCampaignSelectionModal';
import { useAppData } from '../../../../context/AppContext';
import { InfoIcon, RightUpArrowIcon } from '../../../../assets';
import { truncateStringWithMaxLength } from '../../../../helpers/displayDataHelpers';
import useOutsideClick from '../../../../hooks/useOutsideClicks';

const SelectCampaignAdCampaign: React.FC<SelectCampaignAdCampaignProps> = ({
  formikValues,
  setSelectedFacebookCampaign,
  selectedFacebookCampaign,
}) => {
  const { setShowModalBackground } = useAppData();
  const [isAdCampaignSelectionModalOpen, setIsAdCampaignSelectionModalOpen] =
    useState(false);
  const adCampaignModalRef = useRef<HTMLDivElement>(null);
  const excludeOutsideClickRef = useRef<HTMLDivElement>(null);

  const handleShowAdCampaignSelectionModal = () => {
    setShowModalBackground(true);
    setIsAdCampaignSelectionModalOpen(true);
  };

  const handleCloseAdCampaignSelectionModal = () => {
    setIsAdCampaignSelectionModalOpen(false);
    setShowModalBackground(false);
  };

  useOutsideClick(
    adCampaignModalRef,
    () => handleCloseAdCampaignSelectionModal(),
    excludeOutsideClickRef
  );

  const isSelectedPlatformSMS = formikValues?.platforms
    ?.map((platform: string) => platform.toLowerCase())
    .includes('sms');

  return (
    <>
      <div className="d-flex-start-start w-100 p-relative ">
        <div className="d-flex-row">
          <label className="campaign-label-input">Ad Campaign Selection</label>
          <div className="d-flex-center icon-hovered ml-0_5rem pb-0_5rem">
            <InfoIcon />
          </div>
        </div>
        <div
          className={`${isSelectedPlatformSMS ? 'pointer' : 'opacity-0_5'} d-flex-row campaign-input w-100`}
          onClick={
            isSelectedPlatformSMS
              ? handleShowAdCampaignSelectionModal
              : () => {}
          }
        >
          <span>
            {selectedFacebookCampaign?.facebookAdName
              ? truncateStringWithMaxLength(
                  selectedFacebookCampaign?.facebookAdName,
                  30
                )
              : 'No Ad Campaign selected'}
          </span>
          <div className="d-flex-center w-1rem h-1rem p-absolute top-2_65rem right-0_625rem ">
            <RightUpArrowIcon className="top-2_4rem" />
          </div>
        </div>
      </div>
      {isAdCampaignSelectionModalOpen && (
        <div className="d-flex-start-start" ref={adCampaignModalRef}>
          <AdCampaignSelectionModal
            selectedFacebookCampaign={selectedFacebookCampaign}
            setSelectedFacebookCampaign={setSelectedFacebookCampaign}
            closeModal={handleCloseAdCampaignSelectionModal}
          />
        </div>
      )}
    </>
  );
};

export default SelectCampaignAdCampaign;
