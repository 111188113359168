import React from 'react';
import { SettingsHeader } from './components';
import {
  HubspotRoundIcon,
  PipedriveRoundIcon,
  SalesforceRoundIcon,
  SettingsGearIcon,
  ZohoRoundIcon,
} from '../../assets';
import {
  HUBSPOT_CLIENT_ID,
  HUBSPOT_REDIRECT_URI,
} from '../../helpers/envProcessor';

const SettingsIntegrations: React.FC = () => {
  const handleSyncLeads = () => {
    try {
      if (HUBSPOT_CLIENT_ID && HUBSPOT_REDIRECT_URI) {
        const authUrl =
          'https://app-eu1.hubspot.com/oauth/authorize' +
          `?client_id=${encodeURIComponent(HUBSPOT_CLIENT_ID)}` +
          `&scope=crm.lists.read%20crm.objects.contacts.read%20crm.schemas.contacts.read` +
          `&redirect_uri=${encodeURIComponent(HUBSPOT_REDIRECT_URI)}`;

        console.log(authUrl); // Log the auth URL for debugging
        window.open(authUrl, '_blank', 'noopener,noreferrer'); // Open in a new tab
      } else {
        console.error('HubSpot Client ID or Redirect URI is missing.');
      }
    } catch (error) {
      console.error(
        'An unexpected error occurred while trying to sync leads:',
        error
      );
    }
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <SettingsHeader />
      <div className="d-flex-start-start w-100 mt-1rem">
        <div className="d-flex-start-start mb-2_375rem">
          <span className="fw-600 c-111928 fs-1_125rem lh-1_125rem ff-poppins mb-0_5rem">
            Integrations
          </span>
        </div>
        <div className="d-flex-row-space-between w-100 mb-2rem">
          {/*<div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <SalesforceRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Salesforce</span>
              </div>
              <div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Online platform for sending professionals e-mails to mass
              recipients.
            </span>
            <button className="btn-agent-bio-save w-100">Connect</button>
          </div>*/}
          <div className="d-flex-start-start bg-white w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <HubspotRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Hubspot</span>
              </div>
              <div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Online platform for sending professionals e-mails to mass
              recipients.
            </span>
            <button
              className="btn-agent-bio-save w-100 "
              onClick={handleSyncLeads}
            >
              Connect
            </button>
          </div>
        </div>
        {/*<div className="d-flex-row-space-between w-100">
          <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <ZohoRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Zoho</span>
              </div>
              <div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Online platform for sending professionals e-mails to mass
              recipients.
            </span>
            <button className="btn-agent-bio-save w-100">Connect</button>
          </div>
          <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
            <div className="d-flex-row-space-between w-100 mb-0_5rem">
              <div className="d-flex-row">
                <div className="d-flex-center mr-0_375rem">
                  <PipedriveRoundIcon />
                </div>
                <span className="ff-poppins fw-600 c-111928">Pipedrive</span>
              </div>
              <div className="d-flex-center icon-hovered">
                <SettingsGearIcon />
              </div>
            </div>
            <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
              Online platform for sending professionals e-mails to mass
              recipients.
            </span>
            <button className="btn-agent-bio-save w-100">Connect</button>
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default SettingsIntegrations;
