interface LeadsFilterConfigProps {
  tags: string[];
  itemsPerPage: number[];
}

interface LeadsHeaderDropdownsConfigProps {
  showLeads: string[];
  sortLeadsBy: string[];
}

interface LeadsActionDropdownConfigProps {
  actionDropdown: string[];
}

export const LeadsFilterConfig: LeadsFilterConfigProps = {
  tags: [],
  itemsPerPage: [10, 15, 20, 30, 50],
};

export const LeadsHeaderDropdownsConfig: LeadsHeaderDropdownsConfigProps = {
  showLeads: ['All leads', 'Option 1', 'Option 2'],
  sortLeadsBy: ['Date (newest)', 'Date (oldest)', 'Name (A-Z)', 'Name (Z-A)'],
};

export const LeadsActionDropdownConfig: LeadsActionDropdownConfigProps = {
  actionDropdown: ['Action', 'Option 1', 'Option 2'],
};
