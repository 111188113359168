import React, { useRef } from 'react';

import { selectSubscriptionPlan } from '../../core/requests/requests';
import { CloseIcon } from '../../assets';
import { PaymentsMethods } from '../../pages/settings/paymentsComponents';
import useOutsideClick from '../../hooks/useOutsideClicks';

interface AddPaymentMethodModal {
  closeModal: () => void;
  selectedSubscriptionPlanId: string;
  handleFinishSetup: () => void;
}

const AddPaymentMethodModal: React.FC<AddPaymentMethodModal> = ({
  closeModal,
  selectedSubscriptionPlanId,
  handleFinishSetup,
}) => {
  const addPaymentModalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(addPaymentModalRef, () => closeModal());

  const handleSelectSubscriptionPlan = async () => {
    try {
      if (selectedSubscriptionPlanId) {
        const { data } = await selectSubscriptionPlan(
          selectedSubscriptionPlanId
        );
        if (data) {
          window.open(data.invoiceHosted, '_blank');
          handleFinishSetup();
          closeModal();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="d-flex-start-start w-44rem bg-white p-1_5rem shadow-box-11 b-radius-0_75rem max-h-90vh overflow-auto"
      ref={addPaymentModalRef}
    >
      <div className="d-flex-row-space-between w-100 mb-1rem">
        <span className="ff-inter c-111928 fw-700 fs-1_25rem">
          Select Payment Method
        </span>
        <div className="d-flex-center icon-hovered" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <PaymentsMethods
        handleSelectSubscriptionPlan={handleSelectSubscriptionPlan}
        isModal={true}
      />
    </div>
  );
};

export default AddPaymentMethodModal;
