import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import {
  CampaignDataProps,
  CampaignSettingsProps,
  InitialSettingsFormikValuesProps,
} from './components/startCampaignModels';

import {
  createCampaign,
  importFilesByCampaignId,
  updateCampaign,
} from '../../core/requests/requests';
import {
  backgroundInformationValidationSchema,
  campaignLinkValidationSchema,
  campaignNameValidationSchema,
  goalAndFocusValidationSchema,
  platformValidationSchema,
} from '../auth/components/ValidationSchemaItems';
import { campaignViewValues } from '../../config/CampaignsConfig';
import { getFormattedStringFromDate } from '../../helpers/campaignHelpers';
import { useAppData } from '../../context/AppContext';
import { InfoModal } from '../../components';
import { FacebookLeadsProps } from '../../core/types/_models';
import { FileProcessor } from './components';
import {
  SelectCampaignTimezone,
  SelectLaunchCampaignDropdown,
  SelectCampaignDateRange,
  SelectCampaignAdPlatform,
  SelectCampaignTone,
  SelectCampaignAgents,
  SelectCampaignAdCampaign,
} from './components/campaignSettingsFormComponents';

const CampaignSettings: React.FC<CampaignSettingsProps> = ({
  setCampaignData,
}) => {
  const {
    setCurrentStartCampaignView,
    setShowModalBackground,
    setCurrentCampaign,
    currentCampaign,
  } = useAppData();

  const initialFormikValues: InitialSettingsFormikValuesProps = {
    name: currentCampaign?.name ?? '',
    startDate: currentCampaign?.startDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.startDate))
      : getFormattedStringFromDate(new Date()),
    endDate: currentCampaign?.endDate
      ? getFormattedStringFromDate(new Date(currentCampaign?.endDate))
      : getFormattedStringFromDate(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        ),
    campaignLaunch: currentCampaign?.launchTime ?? '00:00:00',
    timezone: currentCampaign?.timeZone ?? -6,
    agentId: currentCampaign?.agentId ?? '',
    link: currentCampaign?.productLink ?? '',
    goalAndFocus: currentCampaign?.goals ?? '',
    toneSelection: currentCampaign?.tone ?? 'Empathetic',
    backgroundInformation: currentCampaign?.backgroundInfo ?? '',
    platforms: currentCampaign?.platforms ?? [],
  };

  const [formikValues, setFormikValues] =
    useState<InitialSettingsFormikValuesProps>(initialFormikValues);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [selectedFacebookCampaign, setSelectedFacebookCampaign] =
    useState<FacebookLeadsProps | null>(null);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const handleShowUpdateModal = () => {
    setShowModalBackground(true);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowModalBackground(false);
    setShowUpdateModal(false);
  };

  const handleSubmit = async (values: InitialSettingsFormikValuesProps) => {
    try {
      if (currentCampaign) {
        const updatedPayload: CampaignDataProps = {
          ...currentCampaign,
          name: values.name,
          agentId: formikValues?.agentId,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: formikValues?.timezone,
          productLink: values.link,
          goals: values.goalAndFocus,
          tone: formikValues?.toneSelection,
          backgroundInfo: values.backgroundInformation,
          platforms: values?.platforms,
        };

        const fetchedCampaignData = await updateCampaign(updatedPayload);
        if (fetchedCampaignData?.data) {
          handleShowUpdateModal();
          setTimeout(() => {
            setCampaignData(fetchedCampaignData?.data);
            setCurrentCampaign(fetchedCampaignData?.data ?? '');
            setCurrentStartCampaignView(campaignViewValues?.startCampaign);
            handleCloseUpdateModal();
          }, 1500);
        }
      } else {
        const upperCasePlatforms = values?.platforms.map((platform) =>
          platform?.toUpperCase()
        );
        const updatedPayload: CampaignDataProps = {
          agentId: formikValues?.agentId,
          phoneId: null,
          region: 'us',
          name: values.name,
          startDate: formikValues?.startDate,
          endDate: formikValues?.endDate,
          launchTime: formikValues?.campaignLaunch,
          timeZone: formikValues?.timezone,
          productLink: values.link,
          goals: values.goalAndFocus,
          tone: formikValues?.toneSelection,
          backgroundInfo: values.backgroundInformation,
          type: '',
          platforms: upperCasePlatforms,
          importFacebookLeadsCampaignId:
            selectedFacebookCampaign?.campaignId ?? '',
        };
        const fetchedCampaignData = await createCampaign(updatedPayload);
        if (fetchedCampaignData?.data) {
          setCampaignData(fetchedCampaignData?.data);
          setCurrentStartCampaignView(campaignViewValues?.startCampaign);
          setCurrentCampaign(fetchedCampaignData?.data ?? '');
          if (fetchedCampaignData?.data?.id && fileToUpload) {
            try {
              const { data } = await importFilesByCampaignId(
                fileToUpload,
                fetchedCampaignData?.data?.id
              );
            } catch (error) {
              console.error('Error uploading file:', error);
              alert('Error uploading file. Please try again.');
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: campaignNameValidationSchema,
    link: campaignLinkValidationSchema,
    platforms: platformValidationSchema,
    goalAndFocus: goalAndFocusValidationSchema,
    backgroundInformation: backgroundInformationValidationSchema,
  });

  return (
    <>
      <Formik
        initialValues={formikValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isValid, setFieldValue }) => (
          <Form className="w-100 mt-1_5rem">
            <div className="d-flex-start-between-row w-100">
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Name of Campaign *
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="First Property Viewing "
                    required
                    className={`${errors?.name ? 'error' : ''} campaign-input w-100`}
                  />
                  {errors?.name && (
                    <div className="form-error">{errors?.name}</div>
                  )}
                </div>
                <SelectCampaignDateRange
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                />
                <div className="d-flex-start-start-row w-100 mb-1rem">
                  <SelectLaunchCampaignDropdown
                    formikValues={formikValues}
                    setFormikValues={setFormikValues}
                  />

                  <SelectCampaignTimezone
                    setFormikValues={setFormikValues}
                    formikValues={formikValues}
                  />
                </div>
                <SelectCampaignAgents
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  errors={errors}
                />
              </div>
              <div className="d-flex-start-start w-100 mr-1rem mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Link to be listed in conversations *
                  </label>
                  <Field
                    type="text"
                    id="link"
                    name="link"
                    placeholder="https:www.calendly.com/astreaux"
                    required
                    className={`${errors?.name ? 'error' : ''} campaign-input w-100`}
                  />
                  {errors?.link && (
                    <div className="form-error">{errors?.link}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem  p-relative">
                  <label className="campaign-label-input">
                    Goal and Focus of Conversation
                  </label>
                  <Field
                    type="text"
                    id="goalAndFocus"
                    name="goalAndFocus"
                    placeholder="Book an appointment to an open house"
                    className={`${errors?.goalAndFocus ? 'error' : ''} campaign-input w-100`}
                  />
                  {errors?.goalAndFocus && (
                    <div className="form-error">{errors?.goalAndFocus}</div>
                  )}
                </div>
                <SelectCampaignTone
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                />
                <SelectCampaignAdCampaign
                  formikValues={formikValues}
                  selectedFacebookCampaign={selectedFacebookCampaign}
                  setSelectedFacebookCampaign={setSelectedFacebookCampaign}
                />
              </div>
              <div className="d-flex-start-start w-100 mw-19rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label className="campaign-label-input">
                    Background Information
                  </label>
                  <Field
                    as="textarea"
                    id="backgroundInformation"
                    name="backgroundInformation"
                    className={`${errors?.backgroundInformation ? 'error' : ''} campaign-textarea w-100`}
                    placeholder="The addresss is 123 Cloverfield lane. The open house starts at 5pm and ends at 7pm. Visitors must show up on time."
                  />
                  {errors?.backgroundInformation && (
                    <div className="form-error">
                      {errors?.backgroundInformation}
                    </div>
                  )}
                </div>
                <SelectCampaignAdPlatform
                  formikValues={formikValues}
                  setFormikValues={setFormikValues}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <button
                  type="submit"
                  className="btn-header-primary"
                  disabled={!isValid}
                >
                  <span>Apply</span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <FileProcessor setFileToUpload={setFileToUpload} />
      {showUpdateModal && (
        <InfoModal
          title="Campaign updated successfully!"
          onClose={handleCloseUpdateModal}
        />
      )}
    </>
  );
};

export default CampaignSettings;
