import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  startOfYear,
  endOfYear,
  addYears,
} from 'date-fns';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface StaticRangeHandler {
  range: () => DateRange;
  isSelected: (range: DateRange) => boolean;
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfNextWeek: startOfWeek(addDays(new Date(), 7)),
  endOfNextWeek: endOfWeek(addDays(new Date(), 7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfTomorrow: startOfDay(addDays(new Date(), 1)),
  endOfTomorrow: endOfDay(addDays(new Date(), 1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),
  startOfNextYear: startOfYear(addYears(new Date(), 1)),
  endOfNextYear: endOfYear(addYears(new Date(), 1)),
};

const staticRangeHandler: StaticRangeHandler = {
  range: () => ({}) as DateRange,
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

function createStaticRanges(ranges: any[]) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Tomorrow',
    range: () => ({
      startDate: defineds.startOfTomorrow,
      endDate: defineds.endOfTomorrow,
    }),
  },
  {
    label: 'Next Week',
    range: () => ({
      startDate: defineds.startOfNextWeek,
      endDate: defineds.endOfNextWeek,
    }),
  },
  {
    label: 'Next Month',
    range: () => ({
      startDate: defineds.startOfNextMonth,
      endDate: defineds.endOfNextMonth,
    }),
  },
  {
    label: 'Next Year',
    range: () => ({
      startDate: defineds.startOfNextYear,
      endDate: defineds.endOfNextYear,
    }),
  },
]);
