import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import { useAuth } from '../context/AuthContext';
import AdditionalInfoRoutes from './AdditionalInfoRoutes';
import PrivateRoutes from './PrivateRoutes';
import AuthSelectSubscription from '../pages/selectSubscription/AuthSelectSubscription';

const AppRoutes: React.FC = () => {
  const { authLogin, currentUser } = useAuth();

  const isLoggedIn = authLogin?.accessToken && currentUser?.subscription;
  const loggedInWithoutSubscription =
    authLogin?.accessToken &&
    !currentUser?.askAdditionalInfo &&
    !currentUser?.subscription;
  const requiresAdditionalInfo = isLoggedIn && currentUser?.askAdditionalInfo;
  const loggedInWithoutAdditionalInfo =
    authLogin?.accessToken && currentUser?.askAdditionalInfo;

  return (
    <Router>
      <Routes>
        {isLoggedIn && !requiresAdditionalInfo && (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
          </>
        )}

        {loggedInWithoutAdditionalInfo && (
          <>
            <Route path="/*" element={<AdditionalInfoRoutes />} />
            <Route path="/" element={<Navigate to="/introduction" />} />
          </>
        )}

        {loggedInWithoutSubscription && (
          <Route path="/*" element={<AuthSelectSubscription />} />
        )}

        {!isLoggedIn && (
          <>
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
