import React, { useState, useEffect } from 'react';

import { useAuth } from '../../context/AuthContext';
import {
  changeEmailExample,
  getEmailExample,
  removeEmailExample,
  sendEmailExample,
} from '../../core/requests/requests';
import { PlusIcon } from '../../assets';
import { EmailExamplesProps } from '../../core/types/_models';
import { useAppData } from '../../context/AppContext';
import { ConfirmModal } from '../../components';

const EmailAssistant: React.FC = () => {
  const { currentUser } = useAuth();
  const { setShowModalBackground } = useAppData();
  const [showEmailText, setShowEmailText] = useState(false);
  const [emailText, setEmailText] = useState<string>('');
  const [emailExamples, setEmailExamples] = useState<EmailExamplesProps[] | []>(
    []
  );
  const [modifiedEmailTexts, setModifiedEmailTexts] = useState<{
    [key: string]: string;
  }>({});
  const [savingStates, setSavingStates] = useState<{ [key: string]: string }>(
    {}
  );
  const [showRemoveEmailModal, setShowRemoveEmailModal] = useState(false);
  const userId = currentUser?.id;
  const [selectedEmailId, setSelectedEmailId] = useState<string>('');

  const handleShowRemoveEmailModal = (emailId: string) => {
    setSelectedEmailId(emailId);
    setShowRemoveEmailModal(true);
    setShowModalBackground(true);
  };

  const handleCloseRemoveEmailModal = () => {
    setShowRemoveEmailModal(false);
    setShowModalBackground(false);
    setSelectedEmailId('');
  };

  const fetchEmailExamples = async () => {
    try {
      if (userId) {
        const { data } = await getEmailExample(userId);
        if (data) {
          setEmailExamples(
            data.sort(
              (a: EmailExamplesProps, b: EmailExamplesProps) =>
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
            )
          );
          const initialTexts = data.reduce(
            (acc: any, email: EmailExamplesProps) => {
              acc[email.id] = email.emailText;
              return acc;
            },
            {}
          );
          setModifiedEmailTexts(initialTexts);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendEmailExample = async () => {
    if (userId) {
      const { data } = await sendEmailExample(userId, emailText);
      if (data) {
        fetchEmailExamples();
        setEmailText('');
      }
    }
    setShowEmailText(false);
  };

  const handleChangeEmailExample = async (
    emailId: string,
    changedEmailText: string
  ) => {
    if (emailId) {
      try {
        setSavingStates((prev) => ({ ...prev, [emailId]: 'Saving...' }));
        const { data } = await changeEmailExample(emailId, changedEmailText);
        if (data) {
          fetchEmailExamples();
          setSavingStates((prev) => ({ ...prev, [emailId]: 'Saved' }));
          setTimeout(() => {
            setSavingStates((prev) => ({ ...prev, [emailId]: 'Save' }));
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setSavingStates((prev) => ({ ...prev, [emailId]: 'Save' }));
      }
    }
  };

  const handleAddEmailClick = () => {
    setShowEmailText(true);
  };

  const handleEmailTextChange = (id: string, newText: string) => {
    setModifiedEmailTexts((prev) => ({
      ...prev,
      [id]: newText,
    }));
  };

  useEffect(() => {
    fetchEmailExamples();
  }, []);

  const handleRemoveEmail = async () => {
    try {
      if (selectedEmailId) {
        const response = await removeEmailExample(selectedEmailId);
        if (response?.status === 204 || response?.status === 200) {
          handleCloseRemoveEmailModal();
          fetchEmailExamples();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <h3 className="h2-primary mb-1_25rem">Email examples:</h3>
      {emailExamples?.length > 0 &&
        emailExamples.map((email) => (
          <div className="w-100 mb-1rem p-relative" key={email?.id}>
            <textarea
              value={modifiedEmailTexts[email.id] || ''}
              onChange={(e) => handleEmailTextChange(email.id, e.target.value)}
              placeholder="Paste your email here..."
              rows={10}
              className="w-100 p-1rem settings-textarea"
            ></textarea>
            <div className="d-flex-row p-absolute bottom-1rem right-1rem">
              <button
                className="btn btn-remove w-8rem mr-0_5rem"
                onClick={() => handleShowRemoveEmailModal(email.id)}
              >
                Delete
              </button>
              <button
                className="btn btn-light-primary w-8rem "
                onClick={() =>
                  handleChangeEmailExample(
                    email.id,
                    modifiedEmailTexts[email.id]
                  )
                }
              >
                {savingStates[email.id] || 'Save'}
              </button>
            </div>
          </div>
        ))}

      {showEmailText ? (
        <div className="w-100  mb-3rem">
          <textarea
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}
            placeholder="Paste your email here..."
            rows={10}
            className="w-100  p-1rem settings-textarea pb-4rem"
          ></textarea>
          <button
            className="btn btn-light-primary w-100 mt-0_5rem"
            onClick={handleSendEmailExample}
          >
            Save email example
          </button>
        </div>
      ) : (
        <div
          className="d-flex-center add-email-container w-100 mb-3rem p-1rem"
          onClick={handleAddEmailClick}
        >
          <div className="d-flex-row">
            <PlusIcon />
            <span className="poppins-medium fs-1rem pl-1rem">Add an email</span>
          </div>
        </div>
      )}
      {showRemoveEmailModal && (
        <div className="d-flex-center p-fixed bg-modal top-0 left-0 size-max z4">
          <ConfirmModal
            title="Are you sure you want to remove email example?"
            onConfirm={handleRemoveEmail}
            onCancel={handleCloseRemoveEmailModal}
          />
        </div>
      )}
    </div>
  );
};

export default EmailAssistant;
