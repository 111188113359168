import { useEffect } from 'react';

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
  excludeRef?: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if excludeRef exists and if the clicked element is within excludeRef
      if (
        excludeRef &&
        excludeRef.current &&
        excludeRef.current.contains(event.target as Node)
      ) {
        return; // Do nothing if clicked inside the excludeRef
      }

      // Handle outside click only if ref is present and not clicked within the excludeRef
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, excludeRef]);
};

export default useOutsideClick;
