import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PaymentsCurrentPlan, PaymentsMethods } from './paymentsComponents';
import { SettingsHeader } from './components';

const Payments: React.FC = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

  return (
    <Elements stripe={stripePromise}>
      <div className="d-flex-start-start w-100 p-1_875rem">
        <SettingsHeader />
        <div className="d-flex-start-between-row w-100 ">
          <div className="d-flex-start-start w-calc-50_minus_0_75rem">
            <PaymentsCurrentPlan />
            {/*<PaymentsBillingDetails />*/}
          </div>
          <div className="d-flex-start-start w-calc-50_minus_0_75rem">
            <PaymentsMethods />
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default Payments;
