import React, { useRef } from 'react';
import useOutsideClick from '../../hooks/useOutsideClicks';

interface ConfirmModalProps {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  onConfirm,
  onCancel,
  isLoading,
}) => {
  const showConfirmModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(showConfirmModalRef, () => onCancel());
  return (
    <div className="d-flex-center w-100">
      <div
        className="d-flex-start-start w-20rem z4 bg-white p-2rem b-radius-1rem"
        ref={showConfirmModalRef}
      >
        <h2>{title}</h2>
        <div className="w-100 d-flex-row-space-between mt-1_5rem">
          <button className="btn-leads-filter w-7rem" onClick={onConfirm}>
            {isLoading ? 'Updating...' : 'Yes'}
          </button>
          <button
            className="btn-subscriptions-secondary w-7rem"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
